import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "../../layouts/Admin";

export const PrivateRoutes = ( {isAuthenticated,
    component: Component,setStatus,
...rest}) => {

    //console.log(rest.location.pathname)
    return (
        <>
            {/* <BrowserRouter>
            <Switch>
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
            </Switch>
            </BrowserRouter> */}

        <Route {...rest}
        component={(props) =>(
            (isAuthenticated)
            ?<Component setStatus={setStatus} {...props}/>
            :<Redirect to="/login"/>
        )}
        />
        </>
        
    )
}
