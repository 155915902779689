import axios from 'axios';
import apiurl from './apiurl';

// const res = {status: 200, json:{
//       "rut": 96885930,
//       "tipo_documento": 39,
//       "folio_ini": 683001,
//       "folio_fin": 703000,
//       "cafserial": 42
//     }}; 

const UploadXMl = async(body,token) => {
    // console.log(file);
    
    // console.log(fcallbacks);
    // const {setModalDescarga,setmodalDescargaMensaje,setMensajeFallo}=fcallbacks;
    return new Promise(async(resolve, reject) => {

        // resolve(res);
        // return true;
        try {
            
            const result = await axios
            .post(apiurl+"uploadxml",
            body, 
            {
            'headers': {
                "Content-Type": "application/json",
                'Authorization':"Bearer " + token
            },
            'crossdomain': true,
            })
            console.log(result);
            resolve(result)
                        
            
            
        } catch (error) {
            
            console.log(error);
            reject(error)
            
        }
    })
}

export default UploadXMl;