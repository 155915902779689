import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import 'assets/css/dataTable.css';
import { actions } from "react-table";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";

const ContainerResultadosCheckDocsBDS3FechaDesdeHasta = ({
    showContainerResultadosCheckDocsBDS3FechaDesdeHasta,
    showTitle,
    filter,
    columns,
    handleOnChangeFilterResultados,
    actionsMemo,
    fixedHeader,
    paginationComponentOptions,
    tableData,
}) => {

    return (
        <>
            <Container fluid show={showContainerResultadosCheckDocsBDS3FechaDesdeHasta}>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h6">{showTitle}</Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleOnChangeFilterResultados} />
                                <Col xs="15" className="d-flex justify-content-end">
                                    <Button onClick={actionsMemo.onExport}>Exportar</Button>
                                </Col>
                                <DataTable
                                    className="-striped -highlight"
                                    fixedHeader={fixedHeader}
                                    columns={columns}
                                    data={tableData}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    actionsMemo={actionsMemo}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ContainerResultadosCheckDocsBDS3FechaDesdeHasta;
