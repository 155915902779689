import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import Select from 'react-select'
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import RangosFolios from "../../views/RangosFolios";
import CheckDocsComparacion from "../../views/CheckDocsComparacion";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";

const ContainerRangosFolios = ({

  showContainerRangosFolios,
  showTitle,
  empresaOptions,
  showEmpresa,
  companyLoading,
  tipoDocumento,
  tipoDocumentoOptions,
  deviceOptions,
  defaultDevice,
  device,
  showDevice,
  deviceLoading,
  startDate,
  endDate,
  handleSearch,
  responseData,
  columns,
  paginationOpciones,
  actionsMemo,
  fixedHeader,
  onChange,
  onChangeShowEmpresa,
  onChangeTipoDocumento,
  onChangeShowDevice,
  empresa,
  folioIni,
  folioFin


}) => {

  // console.log(" RR11 ContainerRangosFolios props:", {

  //   showContainerRangosFolios,
  //   showTitle,
  //   empresaOptions,
  //   showEmpresa,
  //   companyLoading,
  //   tipoDocumento,
  //   tipoDocumentoOptions,
  //   deviceOptions,
  //   defaultDevice,
  //   showDevice,
  //   deviceLoading,
  //   startDate,
  //   endDate,
  //   handleSearch,
  //   responseData,
  //   columns,
  //   paginationOpciones,
  //   actionsMemo,
  //   fixedHeader,
  //   onChange,
  //   onChangeShowEmpresa,
  //   onChangeTipoDocumento,
  //   onChangeShowDevice,
  //   empresa,
  //   folioIni,
  //   folioFin


  // });

  return (
    <>
      <Container fluid>
         {/* show={showContainerRangosFolios}> */}
        <Card>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Form.Label>Empresa</Form.Label>
              <Select
                options={empresaOptions}
                defaultValue={empresaOptions[0]}
                // onChange={empresa}
                placeholder="Empresa"
                isLoading={companyLoading}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              {/* <Form.Label>Tipo&nbsp;Documento</Form.Label> */}
              <Form.Label>Tipo Documento</Form.Label>
              <Select
                options={tipoDocumentoOptions}
                defaultValue={tipoDocumentoOptions[0]}
              // onChange={(e) => {
              //   settipoDocumento(e.value);
              // }}
              //value={tipoDocumento}
              // onChange={onChangeTipoDocumento}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Form.Label>Cajero</Form.Label>
              <Select
                options={deviceOptions}
                defaultValue={defaultDevice}
                //onChange={(selectedOption) => onChangeShowDevice(selectedOption)}
                // onChange={onChangeShowDevice}
                isLoading={deviceLoading}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Form.Label>Folio Inicial</Form.Label>
              <Input
                value={folioIni}
              // onChange={(e) => {
              //   if (isNumber(e.target.value) || e.target.value == '') {
              //     setfolioIni(e.target.value)
              //   }
              // }}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Form.Label>Folio Fin</Form.Label>
              <Input
                value={folioFin}
              // onChange={(e) => {
              //   if (isNumber(e.target.value) || e.target.value == '') {
              //     setfolioFin(e.target.value)
              //   }
              // }}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Form.Label>Desde</Form.Label>
              <FormControl
                type="date"
                value={startDate}
                //   onChange={(e) => setStartDate(e.target.value)}
                // onChange={startDate}
              />
            </Col>
            <Col sm={12} md={6} lg={3}>
              <Form.Label>Hasta</Form.Label>
              <FormControl
                type="date"
                value={endDate}
                // onChange={endDate}
              // onChange={(e) => setEndDate(e.target.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 text-nowrap">
              <Button variant="primary" onClick={handleSearch}>Buscar</Button>
            </Col>
            <Col></Col>
            {/* <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={() => {setStartDate("");setEndDate("");setDocType("");setFolio("")} }>Limpiar<FaEraser className="ml-3"/></Button>&nbsp;
                </Col> */}
          </Row>
        </Card>
        {responseData &&
          <Card>
            <Card.Header>Resultados</Card.Header>
            <Card.Body>
              <DataTable
                className="-striped -highlight"
                fixedHeader={true}
                noDataComponent="No hay folios asignados en el rango seleccionado"
                columns={columns}
                data={responseData}
                pagination
                paginationComponentOptions={paginationOpciones}
                actions={actionsMemo}
              />
            </Card.Body>
          </Card>}
      </Container>
    </>
  );
}

export default ContainerRangosFolios;