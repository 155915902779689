import { dataContext } from "components/dataContext/dataContext";
import { AccountContext } from 'components/Account/Account';
import React, { useContext, useEffect, useMemo, useState } from "react";
import '../assets/css/dataTable.css';
import downloadCSV from '../csv/downloadCSV';
import checkDocumentosCargados from 'api/checkDocumentosCargados';
import ContainerCheckDocsBDS3 from 'components/Container/ContainerCheckDocsBDS3';
import ContainerResultadosCheckDocsBDS3 from 'components/Container/ContainerResultadosCheckDocsBDS3';
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';

const CheckDocsBDS3 = () => {

  console.log(' RR0 Lectura Constantes');

  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const { data } = useContext(dataContext);
  const { getSession } = useContext(AccountContext);
  const [showLoading, setShowLoading] = useState(false);
  const [modalTitleLoading, setModalTitleLoading] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showContainerCheckDocsBDS3, setShowContainerCheckDocsBDS3] = useState(true);
  const [showContainerResultadosCheckDocsBDS3, setShowContainerResultadosCheckDocsBDS3] = useState(false);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [filter, setFilter] = useState('');
  const [filtered, setFiltered] = useState([]);

  console.log(' RR1 Lectura useEffect 1');

  useEffect(() => {
    let today = new Date();
    let miliseconds = today.getTime() - 24 * 60 * 60 * 1000; // Restar 2 días
    let yesterday = new Date(miliseconds);
    const firstDayCurrentMonth = getFirstDayOfMonth(yesterday.getFullYear(), yesterday.getMonth());
    setStartDate(firstDayCurrentMonth.toISOString().slice(0, 10));
  }, []);

  console.log(' RR1 Lectura useEffect 2');

  useEffect(() => {
    const stateValues = ['dataFiltered', 'startDate', 'filtered'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered, startDate, filtered]);

  console.log(' RR1 Lectura useEffect 3');

  useEffect(() => {
    if (dataFiltered.length > 0) {
      console.log('RR useEffect dataFiltered:', dataFiltered);
    }
    if (filtered.length > 0) {
      console.log('RR useEffect filtered:', filtered);
    }
  }, [dataFiltered, filtered]);

  console.log(' RR2 Lectura getFirstDayOfMonth');

  const getFirstDayOfMonth = (year, month) => {
    return new Date(year, month, 1);
  };

  console.log(' RR3 Lectura errorMessages');

  const errorMessages = {
    400: '- Bad Request',
    401: '- Unauthorized',
    403: '- Forbidden',
    404: '- Not Found',
    500: '- Internal Server Error',
    502: '- Bad Gateway',
    503: '- Service Unavailable',
    504: '- Gateway Timeout'
  };

  console.log(' RR4 Lectura busqueda');

  const busqueda = async (e) => {
    setShowLoading(true);
    setLoadingDetail(true);
    setModalTitleLoading('C2C BOLETAS CLOUD');
    setLoadingMessage('Buscando ...');
    const fecha = startDate;
    const idToken = await getSession().then((session) => session.idToken.jwtToken);
    try {
      let response = await checkDocumentosCargados(idToken, fecha);
      if (response) {
        if (response.status === 200) {
          if (Object.keys(response.data.empresas).length === 0) {
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('No existen datos que Comparar en la fecha seleccionada');
            return
          } else {
            setLoadingDetail(false);
            setShowLoading(false);
            dataCheck(response.data.empresas);
            setShowContainerResultadosCheckDocsBDS3(true);
            return
          }
        } else if (response.status >= 400 && response.status <= 504) {
          setShowLoading(false);
          setShowErrorModal(true);
          const errorMessage = errorMessages[response.status] || 'Error en el Status de respuesta';
          setErrorMessage(`${errorMessage}: ${response.message}`);
        }
      }
    } catch (error) {
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al comparar: ' + error.message);
    }
  };

  console.log(' RR5 Lectura dataCheck');

  const dataCheck = (response) => {
    try {
      setShowLoading(true);
      setLoadingDetail(true);
      setModalTitleLoading('C2C BOLETAS CLOUD');
      setLoadingMessage('Buscando Diferencias...');
      const enProceso = 'En Proceso';
      const searchValue = ''; // Define el valor de búsqueda aquí
      const filteredData = response.filter((element) => {
        const { rut } = element;
        return rut && rut.trim().includes(searchValue);
      });
      const newDataFiltered = filteredData.map((element) => {
        const { rut, diferencia, totalBD, totalS3, foliosNoCargadosEnBD, foliosRepetidos } = element;
        let dataFoliosNoCargadosEnBD = foliosNoCargadosEnBD;
        let dataFoliosRepetidosCantidad = '';
        let dataDiferencia = totalS3-totalBD;
        const parsedTotalBD = parseFloat(totalBD)
        const foliosRepetidosCantidad = [];
        if (diferencia === null && dataDiferencia < 0) {
          return {
            rut,
            totalBD,
            totalS3,
            diferencia: totalS3,
            foliosNoCargadosEnBD: enProceso,
            foliosRepetidosCantidad: enProceso
          };
        }
        if (diferencia !== null && foliosRepetidos.length !== 0) {
          for (const folioNoCargado of foliosNoCargadosEnBD) {
            const folioRep = foliosRepetidos.find((folioRep) => folioRep.folio === folioNoCargado);
            if (folioRep) {
              const cantidadRepeticiones = folioRep.cantidadRepeticiones;
              foliosRepetidosCantidad.push(`${folioNoCargado} : ${cantidadRepeticiones}`);
            }
          }
        } else if (diferencia !== null && foliosRepetidos.length === 0) {
          for (const folioNoCargado of foliosNoCargadosEnBD) {
            foliosRepetidosCantidad.push(`${folioNoCargado}`);
          }
          dataFoliosRepetidosCantidad = foliosRepetidosCantidad;
        } else if (diferencia === null && foliosRepetidos.length === 0 && foliosNoCargadosEnBD === undefined) {
          dataDiferencia = totalS3;
          dataFoliosNoCargadosEnBD = 'Todos los Elementos en S3 para este RUT';
          dataFoliosRepetidosCantidad = 'Todos los Elementos en S3 para este RUT';
        }
        return {
          rut,
          totalBD: parsedTotalBD,
          totalS3,
          diferencia: dataDiferencia,
          foliosNoCargadosEnBD: dataFoliosNoCargadosEnBD == '' ? '0' : dataFoliosNoCargadosEnBD,
          foliosRepetidosCantidad: foliosRepetidosCantidad == '' ? dataFoliosNoCargadosEnBD : '0'
          // foliosRepetidosCantidad: dataFoliosRepetidosCantidad == '' ? '0' : dataFoliosRepetidosCantidad
        };
      });
      const filteredResults = newDataFiltered.filter(item => !item.error);
      setDataFiltered(filteredResults);
      setFiltered(filteredResults);
      setDataFiltered(newDataFiltered);
      setFiltered(newDataFiltered);
      console.log('RR dataFiltered:', newDataFiltered); // Imprimir los datos filtrados en la consola
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
    } catch (error) {
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
      setShowErrorModal(true);
      setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
    }
  }

  console.log(' RR6 Lectura resetModal');

  const resetModal = () => {
    handleClose()
  };

  console.log(' RR7 Lectura handleClose');

  const handleClose = () => {
    setStartDate(new Date().toISOString().slice(0, 10));
    window.location.reload();
  };

  console.log(' RR8 Lectura handleOnChangeFilter');

  const handleOnChangeFilter = (e) => {
    const newStartDate = e.target.value;
    if (newStartDate !== startDate) {
      setStartDate(newStartDate);
    }
  };

  console.log(' RR9 Lectura actionsMemo');

  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        setShowLoading(true);
        setLoadingDetail(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('Exportando Datos...');
        const timestamp = new Date().toISOString().replace(/:/g, "-");
        const filename = `export_ResultadosCheckDocumentosBD/S3_${timestamp}.csv`;
        if (dataFiltered.length === 0) {
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('No hay datos para exportar');
        } else {
          downloadCSV(dataFiltered, filename);
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('Datos Exportados, documento generado: ' + filename);
        }
      }
    }),
    [dataFiltered]
  );

  console.log(' RR10 Lectura columnas');

  let columns;
  try {
    columns = [
      {
        name: 'Rut Empresa',
        selector: (row) => row.rut,
        sortable: true,
        wrap: true,
        format: (row) => `${row.rut}`,
        width: "10%"
      },
      {
        name: 'Total Doc BD',
        selector: (row) => row.totalBD,
        sortable: true,
        wrap: true,
        format: (row) => `${row.totalBD}`,
        width: "10%"
      },
      {
        name: 'Total Doc S3',
        selector: (row) => row.totalS3,
        sortable: true,
        wrap: true,
        format: (row) => `${row.totalS3}`,
        width: "10%"
      },
      {
        name: 'Dif. Cant. Elementos',
        selector: (row) => row.diferencia,
        sortable: true,
        wrap: true,
        format: (row) => `${row.diferencia}`,
        width: "15%",
        compact: true
      },
      {
        name: 'Informacion Elementos',
        selector: (row) => row.foliosNoCargadosEnBD,
        sortable: true,
        wrap: true,
        format: (row) => `${row.foliosNoCargadosEnBD}`,
        width: "25%",
        compact: true
      },
      {
        name: 'Folios a Reprocesar',
        selector: (row) => row.foliosRepetidosCantidad,
        sortable: true,
        wrap: true,
        format: (row) => `${row.foliosRepetidosCantidad}`,
        width: "35%",
        compact: true
      }
    ];
  } catch (error) {
    setShowErrorModal(true);
    setErrorMessage('Ocurrió un error al cargar las columnas: ' + error.message);
  }

  console.log(' RR11 Lectura paginationComponentOptions');

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  }

  console.log(' RR12 Lectura handleOnChangeFilterResultados');

  const handleOnChangeFilterResultados = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.trim().toLowerCase(); // Convertimos el valor de búsqueda a minúsculas
    const numericSearchValue = Number(searchValue);
    try {
      const aux = filtered.filter((element) => {
        const {
          rut,
          totalBD,
          totalS3,
          diferencia,
          foliosNoCargadosEnBD,
          foliosRepetidosCantidad
        } = element;
        // Convertir los campos a minúsculas antes de comparar
        const lowercaseRut = rut && rut.trim().toLowerCase();
        const lowercaseFoliosNoCargadosEnBD = Array.isArray(foliosNoCargadosEnBD)
          ? foliosNoCargadosEnBD.map(item => item.toLowerCase())
          : foliosNoCargadosEnBD && foliosNoCargadosEnBD.toLowerCase();
        const lowercaseFoliosRepetidosCantidad = Array.isArray(foliosRepetidosCantidad)
          ? foliosRepetidosCantidad.map(item => item.toLowerCase())
          : foliosRepetidosCantidad && foliosRepetidosCantidad.toLowerCase();
        // Verificar si foliosNoCargadosEnBD es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosNoCargadosString = Array.isArray(lowercaseFoliosNoCargadosEnBD)
          ? lowercaseFoliosNoCargadosEnBD.join(',')
          : lowercaseFoliosNoCargadosEnBD;
        // Verificar si foliosRepetidosCantidad es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosRepetidosCantidadString = Array.isArray(lowercaseFoliosRepetidosCantidad)
          ? lowercaseFoliosRepetidosCantidad.join(',')
          : lowercaseFoliosRepetidosCantidad;
        return (
          (lowercaseRut && lowercaseRut.includes(searchValue)) ||
          (typeof totalBD === 'number' && totalBD === numericSearchValue) ||
          (typeof totalS3 === 'number' && totalS3 === numericSearchValue) ||
          (typeof diferencia === 'number' && diferencia === numericSearchValue) ||
          (typeof foliosNoCargadosString === 'string' && foliosNoCargadosString.includes(searchValue)) ||
          (typeof foliosRepetidosCantidadString === 'string' && foliosRepetidosCantidadString.includes(searchValue))
        );
      });
      console.log('aux: ', aux);
      setDataFiltered(aux);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };

  console.log(' RR13 Lectura return');

  return (
    <div>
      {showContainerCheckDocsBDS3 && (
        <ContainerCheckDocsBDS3
          showContainerCheckDocsBDS3={showContainerCheckDocsBDS3.toString()}
          showTitle="Check Documentos BD/S3"
          lastcheck={data.lastcheck}
          startDate={startDate}
          handleOnChangeFilter={handleOnChangeFilter}
          busqueda={busqueda}
          handleClose={handleClose}
        />
      )}
      <div></div>
      {showContainerResultadosCheckDocsBDS3 && (
        <ContainerResultadosCheckDocsBDS3
          showContainerResultadosCheckDocsBDS3={showContainerResultadosCheckDocsBDS3.toString()}
          showTitle="Resultados Check Documentos BD/S3"
          filter={filter}
          handleOnChangeFilterResultados={handleOnChangeFilterResultados}
          fixedHeader={true}
          columns={columns}
          dataFiltered={dataFiltered}
          paginationComponentOptions={paginationComponentOptions}
          actionsMemo={actionsMemo}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
      {showErrorModal && (
        <ModalError
          showTitle="Ha ocurrido un error"
          showErrorModal={showErrorModal}
          errorMessage={errorMessage}
          resetModal={resetModal}
        />
      )}
    </div>
  );
};

export default CheckDocsBDS3;
