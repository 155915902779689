import React, { useContext, useEffect, useMemo, useState } from "react";
import { AccountContext } from 'components/Account/Account';
import Select from 'react-select'
import ContainerRangosFolios from 'components/Container/ContainerRangosFolios';
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import empresas from 'api/empresas';
import getDevicesByTenant from 'api/getDevicesByTenant';
import { Input } from 'reactstrap';
import getRangoFolios from 'api/getRangoFolios';
import downloadCSV from '../csv/downloadCSV'
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';

const columns = [
  {
    name: "Rut",
    selector: (row) => row.rut,
    sortable: true,
    width: "10%",
  },
  {
    name: "Device ID",
    selector: (row) => row.device_id,
    sortable: true,
    width: "35%",
  },

  {
    name: "Tipo de Documento",
    selector: (row) => row.document_type,
    sortable: true,
    width: "15%",
  },
  {
    name: "Folio Inicial",
    selector: (row) => row.folio_ini,
    sortable: true,
    width: "14%",
  },
  {
    name: "Folio Final",
    selector: (row) => row.folio_fin,
    sortable: true,
    width: "14%",
  },
  {
    name: "Fecha petición",
    selector: (row) => new Date(row.fecha_peticion).toISOString().slice(0, 10),
    sortable: true,
    width: "12%",
  },
];

function RangosFolios() {


  const [showContainerRangosFolios, setShowContainerRangosFolios] = useState(true);


  const [showLoading, setShowLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [modalDescarga, setModalDescarga] = useState(false);
  const [loading, setloading] = useState(true);
  const [modalMensaje, setmodalMensaje] = useState("");


  const [errorSearch, setErrorSearch] = useState(false);
  const { getSession } = useContext(AccountContext);
  const [user, setUser] = useState(null);

  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [tipoDocumento, setTipoDocumento] = useState(39);
  //company
  const [empresaList, setEmpresaList] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [empresaOptions, setEmpresaOptions] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(true);
  //   //devices
  const [device, setDevice] = useState('');
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [defaultDevice, setDefaultDevice] = useState({ value: 'Todos', label: "Todos" })

  const [folioIni, setfolioIni] = useState('');
  const [folioFin, setfolioFin] = useState('');

  const [responseData, setResponseData] = useState(null);
  const [dataToExport, setdataToExport] = useState(null);




  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    //console.log('token: ',user.idToken.jwtToken);

    getSession().then((session) => {
      // console.log('session:',session.idToken.jwtToken);
      setUser(session);
      empresas
        .getEmpresas(session.idToken.jwtToken)
        .then((response) => {
          setEmpresaList(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const arrayOptions = [];
    empresaList.forEach((element) => {
      arrayOptions.push({
        value: element.tenant_id,
        label: element.rut + " - " + element.name
      });
    });
    setCompanyLoading(false);
    setEmpresaOptions(arrayOptions);
  }, [empresaList]);

  //   const closeModal = () => {
  //     setModalDescarga(false);
  //   };

  //   function sleep(ms) {
  //     return new Promise((resolve) => setTimeout(resolve, ms));
  //   }



  useEffect(() => {
    async function fetchData() {
      if (empresa !== "" && user !== null) {
        setDeviceLoading(true);
        setDeviceOptions([]);

        try {
          const response = await getDevicesByTenant(user.idToken.jwtToken, empresa);
          const arrayOptions = [{ value: '', label: 'Todos' }];

          response.data.records.forEach((element) => {
            arrayOptions.push({
              value: element.device_id,
              label: element.device_id,
            });
          });

          setDeviceLoading(false);
          setDeviceOptions(arrayOptions);
        } catch (error) {
          // Manejar el error aquí
        }
      }
    }

    fetchData();
  }, [empresa]);



  //   const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Exportar</Button>;
  //   const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(responseData)} />, [responseData]);

  //   const handleSearch = async () => {

  //     try {
  //       console.log(empresa,startDate,endDate,tipoDocumento,'device:',device,'folio_ini:',folioIni,'folio_fin:',folioFin);

  //       const arrayToExport=[];
  //       setModalDescarga(true);
  //       setloading(true);

  //       if(empresa==""){
  //         setmodalMensaje("Debe seleccionar una empresa");
  //         setloading(false);
  //         return;
  //       }
  //       // if(folioIni=="" || folioFin==""){
  //       //   setmodalMensaje("Debe ingresar un rango de folios");
  //       //   setloading(false);
  //       //   return;
  //       // }

  //       if( parseInt(folioIni) > parseInt(folioFin)){
  //         setmodalMensaje("El folio inicial debe ser menor al folio final");
  //         setloading(false);
  //         return;
  //       }

  //       // return;
  //       const params= {tenant_id:empresa,tipodocumento:tipoDocumento,startDate,endDate,device_id:device,folio_ini:folioIni,folio_fin:folioFin}
  //       const response = await getRangoFolios(params, user.idToken.jwtToken );
  //       console.log(response.data);
  //       setloading(false);
  //       setModalDescarga(false);

  //       setResponseData(response.data);
  //       response.data.forEach(element => {
  //         delete element['tenant_id'];
  //         arrayToExport.push(element);
  //       });
  //       setdataToExport(arrayToExport);

  //     } catch (error) {
  //       setmodalMensaje("Error al buscar folios");
  //       setloading(false);
  //       console.log(error);
  //     }
  //   };



  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        try {
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const filename = `export_ResumenDispositivos_${timestamp}.csv`;
          downloadCSV(responseData, filename);
        } catch (error) {
          setShowLoading(false);
          setShowErrorModal(true);
          setErrorMessage('Error al exportar el archivo CSV: ' + filename + ' ' + error.message);
        }
      }
    }),
    [responseData]
  );

  const handleSearch = async () => {

    try {
      console.log(empresa, startDate, endDate, tipoDocumento, 'device:', device, 'folio_ini:', folioIni, 'folio_fin:', folioFin);

      const arrayToExport = [];
      setModalDescarga(true);
      setloading(true);

      if (empresa == "") {
        setmodalMensaje("Debe seleccionar una empresa");
        setloading(false);
        return;
      }
      // if(folioIni=="" || folioFin==""){
      //   setmodalMensaje("Debe ingresar un rango de folios");
      //   setloading(false);
      //   return;
      // }

      if (parseInt(folioIni) > parseInt(folioFin)) {
        setmodalMensaje("El folio inicial debe ser menor al folio final");
        setloading(false);
        return;
      }

      // return;
      const params = { tenant_id: empresa, tipodocumento: tipoDocumento, startDate, endDate, device_id: showDevice, folio_ini: folioIni, folio_fin: folioFin }
      const response = await getRangoFolios(params, user.idToken.jwtToken);
      console.log(response.data);
      setloading(false);
      setModalDescarga(false);

      setResponseData(response.data);
      response.data.forEach(element => {
        delete element['tenant_id'];
        arrayToExport.push(element);
      });
      setdataToExport(arrayToExport);

    } catch (error) {
      setErrorMessage("Error al buscar folios");
      setShowLoading(false);
      console.log(error);
    }
  };

  const onChangeShowEmpresa = (e) => {
    //setEmpresa(e.value);
    const value = e.target.value;
    setEmpresa(value);
    // if (e.target && typeof e.target.value === 'string') {
    //   setShowEmpresa(e.target.value);
    // }
  };

  const onChangeTipoDocumento = (e) => {
    //setTipoDocumento(e.value);
    const value = e.target.value;
    setTipoDocumento(value);
    // Resto de tu código...
    // if (e.target && typeof e.target.value === 'integer') {
    //   setTipoDocumento(e.target.value);
    // }
  };

  const onChangeShowDevice = (e) => {
    const value = e.target.value;
    setDevice(value);
    // Resto de tu código...
  };

  const tipoDocumentoOptions = [
    { value: 39, label: "Boleta Electrónica" },
    { value: 41, label: "Boleta Exenta Electrónica" },
  ];

  const paginationOpciones = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsText: "Todos",
  };

  return (
    <div>
      {showContainerRangosFolios && (
        <ContainerRangosFolios
          showContainerRangosFolios={showContainerRangosFolios.toString()}
          showTitle="Rangos de Folio"
          empresaOptions={empresaOptions}
          showEmpresa={onChangeShowEmpresa}
          companyLoading={companyLoading}
          tipoDocumento={onChangeTipoDocumento}
          tipoDocumentoOptions={tipoDocumentoOptions}
          deviceOptions={deviceOptions}
          defaultDevice={defaultDevice}
          showDevice={onChangeShowDevice}
          deviceLoading={deviceLoading}
          startDate={startDate}
          endDate={endDate}
          handleSearch={handleSearch}
          responseData={responseData}
          columns={columns}
          paginationOpciones={paginationOpciones}
          actionsMemo={actionsMemo}
          fixedHeader={true}
          folioIni={folioIni}
          folioFin={folioFin}
        />
      )}
      {/* {showModalContainerRangoDeFolios && (
        <ModalContainerRangoDeFolios
          showModalContainerRangoDeFolios={showModalContainerRangoDeFolios.toString()}
          showTitleRangoDeFolios="Lista Rangos de Folios"
          lastcheck={data.lastcheck}
          filterRangoDeFolios={filterRangoDeFolios}
          handleChange1={handleChange1}
          auxRangoDeFolios={auxRangoDeFolios}
          actionsMemo2={actionsMemo2}
          fixedHeader={true}
          columnsRangoDeFolios={columnsRangoDeFolios}
          dataFilteredRangoDeFolios={dataFilteredRangoDeFolios}
          pagination={true}
          paginationOpciones={pagination}
        //handleClick={handleClick2}
        />
      )}
      {showModalStatusDispositivo && (
        <ModalStatusDispositivo
          showModalStatusDispositivo={showModalStatusDispositivo}
          showTitle="Status del Dispositivo Seleccionado"
          lastcheck={data.lastcheck}
          handleCancel={handleCancel}
          showModalDataDatosDispositivo={showModalDataDatosDispositivo}
          isObservacionesEnabled={isObservacionesEnabled}
          handleOptionChange={handleOptionChange}
          selectedOption={selectedOption}
          handleOptionChangeObservacion={handleOptionChangeObservacion}
          handleSave={handleSave}
          clickHandler={clickHandler}
          observaciones={observaciones}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
      {showErrorModal && (
        <ModalError
          resetModal={resetModal}
          showErrorModal={showErrorModal}
          setShowErrorModal={setShowErrorModal}
          errorMessages={errorMessages}
        />
      )} */}
    </div>
  );

}


export default RangosFolios;