import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router';
import {AccountContext} from '../Account/Account'
import {
    Col,
    Form
  } from "react-bootstrap";
import { types } from 'types/types';




export const Login = () => {

   const lastPath='/resumen';
   let history = useHistory();

    const {authenticate,dispatch} = useContext(AccountContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    

    const onSubmit = (event) =>{
        event.preventDefault();
        authenticate(email, password).then(data => {
            const action ={
                type: types.login,
                payload: data
            }
    
            dispatch(action);
            history.push(lastPath);
            console.log("Logged!", data)
        }).catch(err => {
            console.error(err);
        })
       
    }
    return (
         <div className="d-flex justify-content-center p-2" >
            <Col md="4">
            <form  onSubmit={onSubmit}>
            <Form.Group className="mb-6" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control value={email} onChange={(event) =>setEmail(event.target.value)} type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control value={password}
                onChange={(event) =>setPassword(event.target.value)} type="password" placeholder="Password" />
            </Form.Group>
                <button className="btn btn-primary"type="submit">Login</button>
            </form>
            </Col>
        </div>
    )
}
