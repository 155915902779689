import React, { useContext, useEffect, useMemo, useState } from "react";
import { dataContext } from "components/dataContext/dataContext";
import { AccountContext } from 'components/Account/Account';
import '../assets/css/dataTable.css';
import updateStatus from 'api/updateStatus';
import downloadCSV from '../csv/downloadCSV'
import ContainerResumen from 'components/Container/ContainerResumen';
import ContainerStatusDispositivo from 'components/Container/ContainerStatusDispositivo';
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';
import ModalActualizarObservaciones from 'components/Modal/ModalActualizarObservaciones';

function Resumen() {

  const { data } = useContext(dataContext);
  const { getSession } = useContext(AccountContext);
  const [filter, setFilter] = useState('');
  const [filtered, setFiltered] = useState([]);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [errors, setErrors] = useState(0);
  const [showContainerResumen, setShowContainerResumen] = useState(true);
  const [showContainerStatusDispositivo, setShowContainerStatusDispositivo] = useState(false);
  const [showDataStatusDispositivo, setShowDataStatusDispositivo] = useState([]);
  const [showDataUpdateStatusDispositivo, setShowDataUpdateStatusDispositivo] = useState([]);
  const [isObservacionesEnabled, setIsObservacionesEnabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState('');
  const [updateSelectedOption, setUpdateSelectedOption] = useState('');
  const [observaciones, setObservaciones] = useState('');
  const [updateObservaciones, setUpdateObservaciones] = useState('');
  const [botonGuardar, setBotonGuardar] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [modalTitleLoading, setModalTitleLoading] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [observacionesRespaldo, setObservacionesRespaldo] = useState([]);
  const [statusRespaldo, setStatusRespaldo] = useState([]);
  const [fechaObservacion, setFechaObservacion] = useState('');
  const [showModalActualizarObservaciones, setShowModalActualizarObservaciones] = useState(false);
  const [showDataActualizaObservacion, setShowDataActualizaObservacion] = useState([]);

  const errorMessages = {
    400: '- Bad Request',
    401: '- Unauthorized',
    403: '- Forbidden',
    404: '- Not Found',
    500: '- Internal Server Error',
    502: '- Bad Gateway',
    503: '- Service Unavailable',
    504: '- Gateway Timeout'
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (data.estado) {
          let dataFilter = data.data.filter(
            (e) => e.dias > 1 && e.dias < 30 && e.empresa !== undefined & e.rut!=='1'
          );
          dataFilter = dataFilter.flatMap((e) => ({
            tenant_id: e.tenant_id,
            rut: e.rut,
            device_id: e.device_id,
            device_name: e.device_name,
            dias: Math.trunc(e.dias),
            empresa: e.empresa,
            status: e.status,
            observaciones: e.observaciones,
          }));
          setErrors(dataFilter.length);
          setFiltered(dataFilter);
          setDataFiltered(dataFilter);
        }
      } catch (error) {
        showErrorModal(true);
        setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
      }
    };
    fetchData();
  }, [data]);

  let columns;
  try {
    columns = [
      {
        name: 'Rut',
        selector: row => row.rut,
        sortable: true,
        wrap: true,
        format: row => `${row.rut}`,
        width: "8%"
      },
      {
        name: 'Empresa',
        selector: row => row.empresa,
        sortable: true,
        wrap: true,
        format: row => `${row.empresa}`,
        width: "12%",
        compact: true
      },
      {
        name: 'ID Dispositivo',
        selector: row => row.device_id,
        sortable: true,
        wrap: true,
        format: row => `${row.device_id}`,
        width: "15%"
      },
      {
        name: 'Nombre Dispositivo',
        selector: row => row.device_name,
        sortable: true,
        wrap: true,
        format: row => `${row.device_name}`,
        width: "15%"
      },
      {
        name: 'Última Conexión',
        selector: row => Math.trunc(row.dias),
        sortable: true,
        textAlign: 'center',
        wrap: true,
        format: row => `${Math.trunc(row.dias)}`,
        width: "10%"
      },
      {
        name: 'Status Device',
        cell: (row) => <span style={{
          width: "28%",
          cursor: row.status != 0 ? 'pointer' : null,
          textAlign: 'center',
          color: row.status != 0 ? 'blue' : null,
        }} onClick={row.status != 0 ? () => { clickHandler(row, 'status') } : null} title="Pulse Click para Cambiar Status del Dispositivo">{row.status}</span>,
        sortable: true,
        wrap: true,
        format: row => `${row.status}`,
        width: "15%",
        compact: true
      },
      {
        name: 'Observaciones',
        cell: (row) => <span style={{
          width: "100%",
          cursor: row.observaciones != 0 ? 'pointer' : null,
          textAlign: 'left',
          color: row.observaciones != 0 ? 'blue' : null,
        }} onClick={row.observaciones != 0 ? () => { clickHandlerActObs(row, 'observaciones') } : null} title="Pulse Click para Actualizar Observaciones del Dispositivo">{row.observaciones}</span>,
        sortable: true,
        wrap: true,
        format: row => `${row.observaciones}`,
        width: "25%",
        compact: true
      }
    ];
  } catch (error) {
    setShowErrorModal(true);
    setErrorMessage('Ocurrió un error al cargar las columnas: ' + error.message);
  }

  const clickHandlerActObs = async (row) => {
    try {
      if (typeof row !== 'object') {
        // } || !row.hasOwnProperty('tenant_id') || !row.hasOwnProperty('rut')) { //|| /* ... otras validaciones */
        setShowErrorModal(true);
        setErrorMessage('Error: Los datos de la fila no son válidos.');
        return;
      }
      setShowModalActualizarObservaciones(true) //use
      setIsObservacionesEnabled(true);
      setBotonGuardar(false);
      const columnsActualizaObservacion = {
        tenant_id: row.tenant_id,
        empresa: row.empresa,
        device_id: row.device_id,
        device_name: row.device_name,
        status: row.status,
        observaciones: row.observaciones
      };
      const updatedString = columnsActualizaObservacion.observaciones.substring(26).slice(0, -2);
      setUpdateObservaciones(updatedString)
      setShowDataActualizaObservacion(columnsActualizaObservacion); //use
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error en el evento clickHandlerActObs: ' + error.message);
    }
  };

  let columnsActualizaObservacion;
  try {
    columnsActualizaObservacion = [
      {
        name: 'Empresa',
        selector: row => row.empresa,
        sortable: true,
        wrap: true,
        format: row => `${row.empresa}`,
        width: "25%",
        compact: true
      },
      {
        name: 'ID',
        selector: row => row.device_id,
        sortable: true,
        wrap: true,
        format: row => `${row.device_id}`,
        width: "25%"
      },
      {
        name: 'Nombre',
        selector: row => row.device_name,
        sortable: true,
        wrap: true,
        format: row => `${row.device_name}`,
        width: "25%"
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        wrap: true,
        format: row => `${row.status}`,
        width: "25%"
      }
    ];
  } catch (error) {
    setShowErrorModal(true);
    setErrorMessage('Ocurrió un error al cargar las columnsActualizaObservacion: ' + error.message);
  }

  const handleOnChangeFilter = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.toLowerCase();
    try {
      if (!Array.isArray(filtered) || typeof setDataFiltered !== 'function') {
        setShowErrorModal(true);
        setErrorMessage('Error: filtered o setDataFiltered no están definidos correctamente.');
        return;
      }
      const aux = filtered.filter((element) => {
        const {
          rut,
          empresa,
          device_id,
          device_name,
          status,
          observaciones,
          dias
        } = element;
        return (
          (rut && rut.trim().includes(searchValue)) ||
          (empresa && empresa.toLowerCase().trim().includes(searchValue)) ||
          (device_id && device_id.toLowerCase().trim().includes(searchValue)) ||
          (device_name && device_name.toLowerCase().trim().includes(searchValue)) ||
          (status && status.toLowerCase().trim().includes(searchValue)) ||
          (observaciones && observaciones.toLowerCase().trim().includes(searchValue)) ||
          (typeof dias === 'number' && dias.toString().includes(searchValue))
        );
      });  
      setDataFiltered(aux);  
      if (aux.length === 0) {
        setShowLoading(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('No se encontraron resultados para filtrar');
      }
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };
  
  const clickHandler = async (row) => {
    try {
      if (typeof row !== 'object' || !row.hasOwnProperty('tenant_id') || !row.hasOwnProperty('rut')) { //|| /* ... otras validaciones */
        setShowErrorModal(true);
        setErrorMessage('Error: Los datos de la fila no son válidos.');
        return;
      }
      setShowContainerResumen(false);
      setShowContainerStatusDispositivo(true);
      setIsObservacionesEnabled(false);
      setBotonGuardar(false);
      const params = {
        tenant_id: row.tenant_id,
        rut: row.rut,
        device_id: row.device_id,
        device_name: row.device_name,
        dias: Math.trunc(row.dias),
        empresa: row.empresa,
        status: row.status,
        observaciones: row.observaciones
      };
      setShowDataStatusDispositivo(params);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error en el evento clickHandler: ' + error.message);
    }
  };

  const handleOnSelectOption = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
    setUpdateSelectedOption((prevSelectedOption) => prevSelectedOption !== option ? option : prevSelectedOption);
    setIsObservacionesEnabled(true);
    setBotonGuardar(true);
  };

  const handleOnChangeObservaciones = (e) => {
    if (e.target && typeof e.target.value === 'string') {
      setUpdateObservaciones(e.target.value);
    }
  };

  const handleSave = async () => {
    try {
      if (showModalActualizarObservaciones && updateObservaciones.trim() === '') {
        setShowErrorModal(true);
        setErrorMessage('Debe agregar información en el campo Observaciones');
        const textarea = document.getElementById('observaciones');
        if (textarea) {
          textarea.focus();
        }
        return;
      } else if (!updateObservaciones) {
        setShowErrorModal(true);
        setErrorMessage('Debe agregar información del cambio de estado del dispositivo en el campo Observaciones');
        return;
      }
      if (selectedOption === showDataStatusDispositivo.status && !ModalActualizarObservaciones) {
        setShowErrorModal(true);
        setErrorMessage('Debe seleccionar un estado del dispositivo diferente al actual.');
        return;
      }
      setShowLoading(true);
      setModalTitleLoading('C2C BOLETAS CLOUD');
      setLoadingDetail(true);
      const fechaObservacion = new Date().toISOString().slice(0, 19).replace("T", " ");
      const observaciones = updateObservaciones !== undefined ? `{${fechaObservacion}}${" : "}{${updateObservaciones}}` : '';
      const observacionesJson = JSON.stringify(observaciones);
      let selectedList = [];
      if (showModalActualizarObservaciones) {
        setLoadingMessage('Actualizando Observaciones del dispositivo...');
        selectedList = [
          showDataActualizaObservacion.tenant_id !== undefined ? showDataActualizaObservacion.tenant_id : '',
          showDataActualizaObservacion.device_id !== undefined ? showDataActualizaObservacion.device_id : '',
          showDataActualizaObservacion.status !== undefined ? showDataActualizaObservacion.status : '',
          observacionesJson !== undefined ? observacionesJson : ''
          //observaciones !== undefined ? observaciones : ''
        ];
      } else {
        setLoadingMessage('Actualizando Status del dispositivo...');
        const selectedOptionValue = selectedOption !== undefined ? selectedOption : '';
        selectedList = [
          showDataStatusDispositivo.tenant_id !== undefined ? showDataStatusDispositivo.tenant_id : '',
          showDataStatusDispositivo.device_id !== undefined ? showDataStatusDispositivo.device_id : '',
          selectedOptionValue,
          observacionesJson !== undefined ? observacionesJson : ''
          //observaciones !== undefined ? observaciones : ''
        ];
      }
      try {
        const session = await getSession();
        if (session && session.idToken) {
          let idToken = session.idToken.jwtToken;
          try {
            let response = await updateStatus(idToken, selectedList);
            if (response) {
              if (response.status === 200) {
                const dataRespaldo = [...dataFiltered];
                const indexDataRespaldo = dataRespaldo.findIndex(
                  (e) => e.device_id === showDataUpdateStatusDispositivo.device_id
                );
                if (indexDataRespaldo >= 0) {
                  dataRespaldo[indexDataRespaldo].status = updateSelectedOption;
                  dataRespaldo[indexDataRespaldo].observaciones = updateObservaciones;
                  setStatusRespaldo(dataRespaldo);
                  setObservacionesRespaldo(dataRespaldo);
                }
                setLoadingDetail(false);
                setLoadingMessage('Dispositivo Actualizado Correctamente');
              } else if (response.status >= 400 && response.status <= 504) {
                setShowLoading(false);
                setShowErrorModal(true);
                const errorMessage = errorMessages[response.status] || 'Error en el Status de respuesta';
                setErrorMessage(`${errorMessage}: ${response.message}`);
              }
            }
          } catch (error) {
            setShowLoading(false);
            setShowErrorModal(true);
            setErrorMessage('Ocurrió un error al actualizar el status: ' + error.message);
          }
        } else {
          setShowLoading(false);
          setShowErrorModal(true);
          setErrorMessage('No se ha iniciado sesión correctamente');
        }
      } catch (error) {
        setShowLoading(false);
        setShowErrorModal(true);
        setErrorMessage('Ocurrió un error en el bloque de sesión y actualización del estado del dispositivo: ' + error.message);
      }
    } catch (error) {
      setShowLoading(false);
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error en el evento handleSave: ' + error.message);
    }
  };

  useEffect(() => {
    showDataUpdateStatusDispositivo.observaciones = fechaObservacion + updateObservaciones;
  }, [fechaObservacion]);

  const handleCancel = () => {
    setShowContainerResumen(true);
    setShowContainerStatusDispositivo(false);
    setShowModalActualizarObservaciones(false);
    setSelectedOption('');
    setUpdateSelectedOption('');
    setObservaciones('');
    setIsObservacionesEnabled(true);
    setBotonGuardar(false);
    setErrorMessage('');
    setShowErrorModal(false);
    setUpdateObservaciones('')
  };

  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        try {
          setShowLoading(true);
          setLoadingDetail(true);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('Exportando Datos...');
          const timestamp = new Date().toISOString().replace(/:/g, "-");
          const filename = `export_ResumenDispositivos_${timestamp}.csv`;
          if (dataFiltered.length === 0) {
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('No hay datos para exportar');
          } else {
            downloadCSV(dataFiltered, filename);
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('Datos Exportados, documento generado: ' + filename );
          }
        } catch (error) {
          setShowLoading(false);
          setLoadingDetail(false);
          setShowErrorModal(true);
          setErrorMessage('Error al exportar el archivo CSV: ' + filename + ' ' + error.message);
          setLoadingMessage('');
        }
      }
    }),
    [dataFiltered]
  );
  

  const resetModal = () => {
    try {
      if (showModalActualizarObservaciones && updateObservaciones.trim() === '') {
        setShowErrorModal(false);
        setErrorMessage('');
        const textarea = document.getElementById('observaciones');
        if (textarea) {
          textarea.focus();
        }
      } else if (showContainerStatusDispositivo && updateObservaciones.trim() === ''){
        setIsObservacionesEnabled(false);
        setBotonGuardar(false);
        setShowErrorModal(false);
        setErrorMessage('');
        const textarea = document.getElementById('observaciones');
        if (textarea) {
          textarea.focus();
        }
        setSelectedOption(statusRespaldo);
      } else {
        setShowModalActualizarObservaciones(false);
        setShowContainerResumen(true); // no es necesario
        setIsObservacionesEnabled(false);
        setBotonGuardar(false);
        setUpdateObservaciones('');
        setSelectedOption(statusRespaldo);
        setObservaciones(observacionesRespaldo);
        setShowErrorModal(false);
        setErrorMessage('');

      }
    } catch (error) {
      setShowLoading(false);
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error en resetModal: ' + error.message);
    }
  };

  useEffect(() => {
    const stateValues = [
      'showLoading',
      'loadingDetail',
      'loadingMessage',
      'showContainerStatusDispositivo',
      'updateObservaciones',
      'botonGuardar',
      'selectedOption',
      'observaciones',
      'showErrorModal',
      'isObservacionesEnabled',
      'modalTitleLoading',
      'errorMessage',
      'fechaObservacion',
      'showModalActualizarObservaciones'
    ];
    stateValues.forEach((state) => {
      console.log(`RR12 useEffect ${state}:`, eval(state));
    });
  }, [showLoading, loadingDetail, loadingMessage, showContainerStatusDispositivo,
    updateObservaciones, botonGuardar, selectedOption, observaciones, showErrorModal,
    isObservacionesEnabled, modalTitleLoading, errorMessage, fechaObservacion, showModalActualizarObservaciones]);

  const handleClose = () => {
    setShowModalActualizarObservaciones(false);
    setShowContainerStatusDispositivo(false);
    setIsObservacionesEnabled(false);
    setBotonGuardar(false);
    setUpdateObservaciones('');
    setObservaciones('');
    setShowErrorModal(false);
    setErrorMessage('');
    setShowContainerResumen(true);
    setSelectedOption('');
    setShowLoading(false);
    setLoadingDetail(false);
    setLoadingMessage('');
    setModalTitleLoading('');
    window.location.reload();
  };

  const PaginationOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  }

  return (
    <div>
      {showContainerResumen && (
        <ContainerResumen
          showContainerResumen={showContainerResumen.toString()}
          showTitle="Resumen Dispositivos Desconectados"
          lastcheck={data.lastcheck}
          filter={filter}
          handleOnChangeFilter={handleOnChangeFilter}
          actionsMemo={actionsMemo}
          columns={columns}
          dataFiltered={dataFiltered}
          PaginationOptions={PaginationOptions}
          dataEstado={data.estado}
          errors={errors}
        />
      )}
      {showContainerStatusDispositivo && (
        <ContainerStatusDispositivo
          showContainerStatusDispositivo={showContainerStatusDispositivo.toString()}
          showTitle="Detalle Dispositivo Desconectado"
          lastcheck={data.lastcheck}
          handleCancel={handleCancel}
          showDataStatusDispositivo={showDataStatusDispositivo}
          isObservacionesEnabled={isObservacionesEnabled}
          selectedOption={selectedOption}
          handleOnSelectOption={handleOnSelectOption}
          handleSave={handleSave}
          updateObservaciones={updateObservaciones}
          handleOnChangeObservaciones={handleOnChangeObservaciones}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
      {showErrorModal && (
        <ModalError
          showTitle="A ocurrido un error"
          showErrorModal={showErrorModal}
          errorMessage={errorMessage}
          resetModal={resetModal}
        />
      )}
      {showModalActualizarObservaciones && (
        <ModalActualizarObservaciones
          showModalActualizarObservaciones={showModalActualizarObservaciones}
          handleClose={handleClose}
          showTitle="Actualizar Observaciones del Dispositivo"
          lastcheck={data.lastcheck}
          showDataActualizaObservacion={showDataActualizaObservacion}
          isObservacionesEnabled={isObservacionesEnabled}
          handleCancel={handleCancel}
          handleSave={handleSave}
          updateObservaciones={updateObservaciones}
          handleOnChangeObservaciones={handleOnChangeObservaciones}
          columnsActualizaObservacion={columnsActualizaObservacion}
        />
      )}
    </div>
  );
}

export default Resumen;