import apiurl from './apiurl';
const axios = require('axios');

const updateStatus = async (idToken, params) => {

  return new Promise(async (resolve, reject) => {
    try {
      const result = await axios.post(apiurl + 'updateStatus', null, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken,
        },
        params: params,
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export default updateStatus;
