import apiurl from './apiurl';
const axios = require('axios');

const consultaFolio= async (params,idToken) =>{
    console.log(params,idToken);
    
    return new Promise(async (resolve,reject) => {
        try {
            const response = await axios({
                'method':'GET',
                'url':apiurl +'consultaestadofolio',
                'headers': {
                    'content-type':'application/json',
                    'Authorization':"Bearer "+idToken
                },
                'crossdomain': true,
                'params': {
                    ...params
                }
            })
            resolve(response.data)
        } catch (error) {
            reject(error);
        }

    })
    
}
export default consultaFolio;