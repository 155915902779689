import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import Resumen from "../../views/Resumen.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";

const ContainerStatusDispositivo = ({

    showContainerStatusDispositivo,
    showTitle,
    lastcheck,
    handleCancel,
    showDataStatusDispositivo,
    isObservacionesEnabled,
    selectedOption,
    handleOnSelectOption,
    handleSave,
    updateObservaciones,
    handleOnChangeObservaciones

}) => {

    return (
        <Container fluid show={showContainerStatusDispositivo}>
            <Card.Header>
            <Card.Title as="h4">{showTitle}</Card.Title>
                <p className="card-category">
                    Última Actualización: {lastcheck}
                </p>
            </Card.Header>
            <Card.Body>
                <div>
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th scope="col" style={{ maxWidth: '100px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Rut : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.rut}</span></th>
                                <th scope="col" style={{ maxWidth: '200px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Empresa : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.empresa}</span></th>
                                <th scope="col" style={{ maxWidth: '200px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>ID Dispositivo : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.device_id}</span></th>
                                <th scope="col" style={{ maxWidth: '200px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Nombre Dispositivo : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.device_name}</span></th>
                                <th scope="col" style={{ maxWidth: '200px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Ultima Conexion : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.dias}</span></th>
                                <th scope="col" style={{ maxWidth: '200px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Status Dispositivo : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.status}</span></th>
                            </tr>
                        </thead>
                    </table>
                    <table className="table table-bordered">
                        <thead className="table-dark">
                            <tr>
                                <th scope="row" style={{ maxWidth: '100px', overflow: 'auto', verticalAlign: 'top', textAlign: 'left' }}>Observaciones : <br /><span style={{ color: 'white', textAlign: 'left' }}>{showDataStatusDispositivo.observaciones}</span></th>
                            </tr>
                        </thead>
                    </table>
                </div>
                <table className="table table-bordered">
                    <thead className="table-dark">
                        <tr>
                            <td scope="row">Por favor, selecciona una opción : <select value={selectedOption} onChange={handleOnSelectOption}>
                                {showDataStatusDispositivo.status !== 'default' && <option value={showDataStatusDispositivo.status}>{showDataStatusDispositivo.status}</option>}
                                <option value="active" disabled={showDataStatusDispositivo.status === 'active'}>Active</option>
                                <option value="suspended" disabled={showDataStatusDispositivo.status === 'suspended'}>Suspended</option>
                                <option value="disabled" disabled={showDataStatusDispositivo.status === 'disabled'}>Disabled</option>
                            </select>
                            </td>
                        </tr>
                    </thead>
                </table>
                {selectedOption && selectedOption !== showDataStatusDispositivo.status && isObservacionesEnabled && (
                    <Form>
                        <Form.Group controlId="observaciones">
                            <Form.Label>Agregar Observaciones:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={1}
                                value={updateObservaciones}
                                onChange={handleOnChangeObservaciones}
                                maxLength={118}
                                placeholder="Ingrese observaciones aquí..."
                                disabled={!isObservacionesEnabled}
                            />
                        </Form.Group>
                    </Form>
                )}
            </Card.Body>
            <Card.Footer style={{ display: 'flex', justifyContent: 'space-between' }}>
                {selectedOption && selectedOption !== showDataStatusDispositivo.status && isObservacionesEnabled && (
                    <Button variant="primary" onClick={handleSave} type="button" className="btn btn-link btn-sm px-3" data-ripple-color="dark">
                        Guardar
                    </Button>
                )}
                <Button variant="secondary" onClick={handleCancel} type="button" className="btn btn-link btn-sm px-3" data-ripple-color="dark">
                    Cancelar
                </Button>
            </Card.Footer>
        </Container>
    );
}

export default ContainerStatusDispositivo;