import { Account } from 'components/Account/Account'
import { AppRouter } from 'components/Routers/AppRouter'
import React, { useEffect, useState } from 'react'

export const MonitorApp = () => {
    

    return (
       
        <Account>
            <AppRouter/>
        </Account>     
        
    )
}
