import { Card, Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button } from "react-bootstrap";
import React, { useContext, useEffect, useMemo, useState } from "react";
import Resumen from "../../views/Resumen.js";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";

const ModalCargando = ({

    modalTitleLoading,
    showLoading,
    handleClose,
    loadingDetail,
    loadingMessage

}) => {

    return (
        <Modal show={showLoading} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title>{modalTitleLoading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{loadingDetail && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}{loadingMessage}</h4>
            </Modal.Body>
            <Modal.Footer>
                {!loadingDetail &&
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
};

export default ModalCargando;