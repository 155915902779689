import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import Resumen from "../../views/Resumen.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";

const ModalActualizarObservaciones = ({
    showModalActualizarObservaciones,
    handleClose,
    showTitle,
    lastcheck,
    showDataActualizaObservacion,
    isObservacionesEnabled,
    handleCancel,
    handleSave,
    updateObservaciones,
    handleOnChangeObservaciones,
    columnsActualizaObservacion

}) => {
    return (
        <Modal show={showModalActualizarObservaciones}  style={{ maxWidth: '2000px', margin: 'auto' }}>
            <Modal.Header style={{ color: 'black', fontSize: '20px', textAlign: 'left' }}>
            {showTitle}
            </Modal.Header>
            <Modal.Body>
            <DataTable className="-striped -highlight"
                                    fixedHeader={true}
                                    columns={columnsActualizaObservacion}
                                    data={[showDataActualizaObservacion]}
                                    highlightOnHover
                                    //pointerOnHover
                                />
                {isObservacionesEnabled && (
                    <Form>
                        <Form.Group controlId="observaciones">
                        <br/>
                            <Form.Label>Actualizar Observaciones:</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={8}
                                value={updateObservaciones}
                                onChange={handleOnChangeObservaciones}
                                maxLength={120}
                            />
                        </Form.Group>
                    </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                {isObservacionesEnabled && (
                    <Button variant="primary" onClick={handleSave} type="button" className="btn btn-link btn-sm px-3" data-ripple-color="dark">
                        Guardar
                    </Button>
                )}
                <Button variant="secondary" onClick={handleCancel} type="button" className="btn btn-link btn-sm px-3" data-ripple-color="dark">
                    Cancelar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalActualizarObservaciones;
