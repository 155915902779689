import React from "react";
import { Modal, Button } from "react-bootstrap";
import Resumen from "../../views/Resumen.js";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";

const ModalError = ({

  showTitle,
  showErrorModal,
  errorMessage,
  resetModal

}) => {
  
  const defaultMessage = "No se encontraron mensajes de error.";

  return (
    <Modal show={showErrorModal} onHide={resetModal}>
      <Modal.Header closeButton>
        <Modal.Title>{showTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMessage || defaultMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={resetModal}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalError;
