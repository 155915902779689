import React, { useContext, useEffect, useMemo, useState } from "react";
import Card from 'react-bootstrap/Card';
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button } from "react-bootstrap";
import { dataContext } from "components/dataContext/dataContext";
import DataTable from 'react-data-table-component';
import '../assets/css/dataTable.css'
import downloadCSV from '../csv/downloadCSV'
import { Input } from "reactstrap";

function ListaEquipos() {
  const { data } = useContext(dataContext);
  const [filtered, setFiltered] = useState([]);
  const [filter, setFilter] = useState('');
  const [dataFiltered, setDataFiltered] = useState(filtered);

  useEffect(() => {
    try {
      if (data.estado) {
        console.log('Lista Equipos');
        const dataFilter = data.data.filter(e => e.empresa !== undefined).flatMap((e) => {
          let folios;
          try {
            folios = e.folios_disponibles[e.rut]?.[39];
            if (e.folios_disponibles[e.rut]?.[41] !== undefined) {
              folios = `${e.folios_disponibles[e.rut][39]} ; ${e.folios_disponibles[e.rut][41]}`;
            }
          } catch (error) {
            console.error(error);
          }
          return {
            rut: e.rut,
            device_id: e.device_id,
            device_name: e.device_name,
            dias: Math.trunc(e.dias),
            empresa: e.empresa,
            folios_disponibles: Math.trunc(folios)
          };
        });
        setFiltered(dataFilter);
        setDataFiltered(dataFilter);
      }
    } catch (error) {
      console.error(error);
    }
  }, [data]);

  const handleChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.toLowerCase();
    try {
      const aux = filtered.filter((element) => {
        const { rut, empresa, device_id, device_name, dias, folios_disponibles } = element;
        return (
          (rut && rut.trim().includes(searchValue)) ||
          (empresa && empresa.toLowerCase().trim().includes(searchValue)) ||
          (device_id && device_id.toLowerCase().trim().includes(searchValue)) ||
          (device_name && device_name.toLowerCase().trim().includes(searchValue)) ||
          (typeof dias === 'number' && dias.toString().includes(searchValue)) ||
          (typeof folios_disponibles === 'number' && folios_disponibles.toString().includes(searchValue))
        );
      });
      setDataFiltered(aux);
      if (aux.length === 0) {
        setShowLoading(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('No se encontraron resultados para filtrar');
      }
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };

  useEffect(() => {
    const stateValues = ['dataFiltered', 'filter'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered, filter]);

  const columns = [
    {
      name: 'Rut',
      selector: row => row.rut,
      sortable: true,
      width: '8%',
    },
    {
      name: 'Cliente',
      selector: row => row.empresa,
      sortable: true,
    },
    {
      name: 'ID Dispositivo',
      selector: row => row.device_id,
      sortable: true,
    },
    {
      name: 'Nombre Dispositivo',
      selector: row => row.device_name,
      sortable: true,
    },
    {
      name: 'Última Conexión',
      selector: row => Math.trunc(row.dias),
      sortable: true,
      width: '12%',
    },
    {
      name: 'Folios Disponibles',
      selector: row => row.folios_disponibles,
      sortable: true,
      width: '12%',
    },
  ];

  const paginationOpciones = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  }

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Exportar</Button>;
  const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(dataFiltered)} />, [dataFiltered]);

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Lista de Equipos C2C</Card.Title>
              <p className="card-category">
                Última Actualización: {data.lastcheck}
              </p>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleChange} />
              <DataTable
                className="-striped -highlight"
                fixedHeader={true}
                columns={columns}
                data={dataFiltered}
                pagination
                paginationComponentOptions={paginationOpciones}
                actions={actionsMemo}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ListaEquipos;
