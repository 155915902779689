import { dataContext } from "components/dataContext/dataContext";
import { AccountContext } from 'components/Account/Account';
import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import '../assets/css/dataTable.css';
import checkDocumentosCargadosDesdeHasta from 'api/checkDocumentosCargadosDesdeHasta';
import downloadCSV from '../csv/downloadCSV';
import ContainerResultadosCheckDocsBDS3FechaDesdeHasta from 'components/Container/ContainerResultadosCheckDocsBDS3FechaDesdeHasta';
import ContainerCheckDocsBDS3DesdeHasta from 'components/Container/ContainerCheckDocsBDS3DesdeHasta';
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';

function CheckDocsBDS3DesdeHasta() {

  const { data } = useContext(dataContext);
  const { getSession } = useContext(AccountContext);
  const [showContainerCheckDocsBDS3DesdeHasta, setShowContainerCheckDocsBDS3DesdeHasta] = useState(true);
  const [tipodocumento, setTipodocumento] = useState('39');
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [filtered, setFiltered] = useState(null)//Filtro para eliminar elementos nulos y asignar solo atributos vistos en tabla
  const [filter, setFilter] = useState('');//value input
  const [dataFiltered, setDataFiltered] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [modalTitleLoading, setModalTitleLoading] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showContainerResultadosCheckDocsBDS3FechaDesdeHasta, setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta] = useState(false);
  const [tableData, setTableData] = useState(dataFiltered);
  let today = new Date();
  let miliseconds = today.getTime() - 24 * 60 * 60 * 1000;//restar 2 días
  let yesterday = new Date(miliseconds);

  // useEffect(() => {
//     console.log('RR1 Lectura useEffect 1');
//     // console.log('RR1 Lectura useEffect 1 data:', data);
//     console.log('RR1 Lectura useEffect 1 dataFiltered:', dataFiltered);
//     console.log('RR1 Lectura useEffect 1 filtered:', filtered);
//     console.log('RR1 Lectura useEffect 1 startDate:', startDate);
//     console.log('RR1 Lectura useEffect 1 endDate:', endDate);
//     console.log('RR1 Lectura useEffect 1 tipodocumento:', tipodocumento);
//     console.log('RR1 Lectura useEffect 1 showContainerCheckDocsBDS3DesdeHasta:', showContainerCheckDocsBDS3DesdeHasta);
//     console.log('RR1 Lectura useEffect 1 showContainerResultadosCheckDocsBDS3FechaDesdeHasta:', showContainerResultadosCheckDocsBDS3FechaDesdeHasta);
//     console.log('RR1 Lectura useEffect 1 tableData:', tableData);
//     console.log('RR1 Lectura useEffect 1 filter:', filter);
//     console.log('RR1 Lectura useEffect 1 showErrorModal:', showErrorModal);
//     console.log('RR1 Lectura useEffect 1 errorMessage:', errorMessage);
//     console.log('RR1 Lectura useEffect 1 showLoading:', showLoading);
//     console.log('RR1 Lectura useEffect 1 modalTitleLoading:', modalTitleLoading);
//     console.log('RR1 Lectura useEffect 1 loadingDetail:', loadingDetail);
//     console.log('RR1 Lectura useEffect 1 loadingMessage:', loadingMessage);
//   }, [ dataFiltered, filtered, startDate, endDate, tipodocumento, showContainerCheckDocsBDS3DesdeHasta, showContainerResultadosCheckDocsBDS3FechaDesdeHasta, tableData, filter, showErrorModal, errorMessage, showLoading, modalTitleLoading, loadingDetail, loadingMessage]);
// // data,
  useEffect(() => {
    if (!dataFiltered || dataFiltered.length === 0) {
      // Si dataFiltered es nulo o no tiene elementos
      const dataDefaultSinDatosDataFiltered = {
        rut: 'No existen Registros de Rut en S3',
        totalDocEmpresa: 0,
        tipodocumento: 'No existen Tipos de Documentos',
        fechaCarga: 'No existen Documentos Cargados en S3',
        filteredFolios: 'No existen Folios en S3',
        filteredFoliosRepetidos: 'no existen Folios Repetidos en S3',
        cantidadTotalEmpresasEns3: 0,
        cantidadTotalDocLeidosEnS3: 0,
        nombreEmpresa: 'No existen Empresas en S3'
      };
      setTableData([dataDefaultSinDatosDataFiltered]);
      return;
    } else {
      // Si dataFiltered tiene elementos
      const rows = dataFiltered.map((item) => {
        const empresaEncontrada = data.find((empresa) => empresa.rut === item.rut);
        const nombreEmpresa = empresaEncontrada ? empresaEncontrada.nombreEmpresa : 'Empresa no encontrada';
  
        return {
          rut: item.rut,
          totalDocEmpresa: item.totalDocEmpresa,
          tipodocumento: item.tipodocumento,
          fechaCarga: item.fechaCarga,
          filteredFolios: item.filteredFolios,
          filteredFoliosRepetidos: item.filteredFoliosRepetidos,
          cantidadTotalEmpresasEns3: item.cantidadTotalEmpresasEns3,
          cantidadTotalDocLeidosEnS3: item.cantidadTotalDocLeidosEnS3,
          nombreEmpresa: nombreEmpresa
        };
      });
      setTableData(rows);
    }
  }, [dataFiltered, data]);

  useEffect(() => {
    const stateValues = ['setTableData'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered]);

  useEffect(() => {
    const stateValues = ['dataFiltered', 'filtered', 'startDate', 'endDate'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered, filtered, startDate, endDate]);

  console.log(' RR1 Lectura useEffect 3');

  useEffect(() => {
    if (dataFiltered != null) {
      console.log('RR useEffect dataFiltered:', dataFiltered);
    }
    if (filtered != null) {
      console.log('RR useEffect filtered:', filtered);
    }
  }, [dataFiltered, filtered]);


  const busqueda = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      setShowErrorModal(true);
      setErrorMessage('Por favor, ingresa las fechas antes de realizar la búsqueda.');
      return;
    }
    setShowLoading(true);
    setModalTitleLoading('Consulta Comparación de Documentos');
    setLoadingDetail(true);
    setLoadingMessage(' Buscando Diferencias...');
    const params = { startDate, endDate, tipodocumento };
    const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
    try {
      let response = await checkDocumentosCargadosDesdeHasta(idToken, params);
      if (response) {
        if (response.status === 200) {
          if (Object.keys(response.data.cantidadDocPorEmpresaDesdeHasta).length === 0) {
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('No existen datos que Comparar en la fecha seleccionada');
            setShowLoading(false);
            return;
          } else {
            setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(true);
            dataCheck(response.data);
            setShowLoading(false);
            return;
          }
        } else if (response.status >= 400 && response.status <= 504) {
          setShowLoading(false);
          setShowErrorModal(true);
          const errorMessage = errorMessage[response.status] || 'Error en el Status de respuesta';
          setErrorMessage(`${errorMessage}: ${response.message}`);
        }
      }
    } catch (error) {
      console.log(error);
      setLoadingMessage('Error', error);
      setLoadingDetail(false);
    }
  };

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    const firstDayCurrentMonth = getFirstDayOfMonth(
      yesterday.getFullYear(),
      yesterday.getMonth(),
    );
    setStartDate(firstDayCurrentMonth.toISOString().slice(0, 10));
    setEndDate(yesterday.toISOString().slice(0, 10));
  }, [])

  const columns = [
    {
      name: 'Total Empresas S3',
      selector: (row) => row.cantidadTotalEmpresasEns3,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Total Doc Cargados en S3',
      selector: (row) => row.cantidadTotalDocLeidosEnS3,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Rut Empresa',
      selector: (row) => row.rut,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Nombre Empresa',
      selector: (row) => row.nombreEmpresa,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Tot. Doc. Cargados en S3',
      selector: (row) => row.totalDocEmpresa,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Tipo Documento',
      selector: (row) => row.tipodocumento,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Fecha Carga',
      selector: (row) => row.fechaCarga,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Folios No Cargados en BD',
      selector: (row) => row.filteredFolios,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Folios Repetidos en S3',
      selector: (row) => row.filteredFoliosRepetidos,
      sortable: true,
      wrap: true,
      width: "10%"
    }
  ];

  const dataCheck = (response) => {
    try {
      let searchValue = {}; // Define el valor de búsqueda aquí
      let filteredData = response.cantidadDocPorEmpresaDesdeHasta.filter((element) => {
        const { rut, tipodocumento, fechaCarga, folios, foliosRepetidos, totalDocEmpresa } = element;
        searchValue = { rut, tipodocumento, fechaCarga, folios, foliosRepetidos, totalDocEmpresa };
        return searchValue; // && rut.trim().includes(searchValue);
      });
      console.log('searchValue: ', searchValue)
      const cantidadEmpresasS3DesdeHasta = response.cantidadEmpresasS3DesdeHasta;
      const totalDocS3DesdeHasta = response.totalDocS3DesdeHasta;
      const datosFiltradosDocFechaDesdeHasta = filteredData.map((element) => {
        const { rut, tipodocumento, fechaCarga, totalDocEmpresa } = element;
        const empresaEncontrada = data.find((empresa) => empresa.rut === rut);

      // Verificar si se encontró la empresa y obtener su nombre
      const nombreEmpresa = empresaEncontrada ? empresaEncontrada.nombreEmpresa : 'Empresa no encontrada';
      // Filtrar los folios y foliosRepetidos
        const filteredFolios = element.folios.map((folioData) => {
          const { folio } = folioData; // Updated variable names here
          return {
            folio: folio, // Updated variable name here
            // tipodocumento: tipodocumento, // Updated variable name here
            // fechaCarga: fechaCarga, // Updated variable name here
            //cantidadRepeticiones: cantidadRepeticiones, // Updated variable name here
          };
        });
        const filteredFoliosRepetidos = element.foliosRepetidos.map((folioRepetido) => {
          const { folio, tipodocumento, fechaCarga, cantidadRepeticiones } = folioRepetido;
          return {
            folio: folio,
            tipodocumento: tipodocumento,
            fechaCarga: fechaCarga,
            cantidadRepeticiones: cantidadRepeticiones,
          };
        });
        return {
          nombreEmpresa: nombreEmpresa  , // Reemplaza esto con el nombre real de la empresa que corresponde a esta fila
          rut: rut,
          totalDocEmpresa: totalDocEmpresa,
          tipodocumento: tipodocumento,
          fechaCarga: fechaCarga,
          filteredFolios: filteredFolios.map(item => `${item.folios} (${item.tipodocumento})`).join('\n'),
          filteredFoliosRepetidos: filteredFoliosRepetidos.map(item => `${item.folio} (${item.tipodocumento}) - Fecha Carga: ${item.fechaCarga} - Cantidad Repeticiones: ${item.cantidadRepeticiones}`).join('\n'),
          cantidadTotalEmpresasEns3: cantidadEmpresasS3DesdeHasta,
          cantidadTotalDocLeidosEnS3: totalDocS3DesdeHasta
        };
      });
      console.log('datosFiltradosDocFechaDesdeHasta: ', datosFiltradosDocFechaDesdeHasta)
      // const filteredResults = newDataFiltered.filter(item => !item.error);
      setDataFiltered(datosFiltradosDocFechaDesdeHasta);
      setFiltered(datosFiltradosDocFechaDesdeHasta);
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
    } catch (error) {
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
      setShowErrorModal(true);
      setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
    }
  }

  const handleOnChangeFilterResultados = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.trim().toLowerCase(); // Convertimos el valor de búsqueda a minúsculas
    const numericSearchValue = Number(searchValue);
    try {
      const aux = filtered.filter((element) => {
        const {
          rut,
          totalBD,
          totalS3,
          diferencia,
          foliosNoCargadosEnBD,
          foliosRepetidos
          // foliosNoCargadosEnBD
        } = element;
        // Convertir los campos a minúsculas antes de comparar
        const lowercaseRut = rut && rut.trim().toLowerCase();
        const lowercaseTotalBD = totalBD && totalBD.trim().toLowerCase();
        const lowercaseTotalS3 = totalS3 && totalS3.trim().toLowerCase();
        const lowercaseDiferencia = diferencia && diferencia.trim().toLowerCase();
        const lowercaseFoliosNoCargadosEnBD = foliosNoCargadosEnBD && foliosNoCargadosEnBD.trim().toLowerCase();
        const lowercaseFoliosRepetidos = foliosRepetidos && foliosRepetidos.trim().toLowerCase();
        // lowercaseFoliosNoCargadosEnBD = Array.isArray(foliosNoCargadosEnBD)
        //   ? foliosNoCargadosEnBD.map(item => item.toLowerCase())
        //   : foliosNoCargadosEnBD && foliosNoCargadosEnBD.toLowerCase();
        // const lowercasefoliosRepetidos = Array.isArray(foliosRepetidos)
        //   ? foliosRepetidos.map(item => item.toLowerCase())
        //   : foliosRepetidos && foliosRepetidos.toLowerCase();
        // Verificar si foliosNoCargadosEnBD es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosNoCargadosString = Array.isArray(lowercaseFoliosNoCargadosEnBD)
          ? lowercaseFoliosNoCargadosEnBD.join(';')
          : lowercaseFoliosNoCargadosEnBD;
        // Verificar si foliosRepetidosCantidad es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosRepetidosString = Array.isArray(lowercaseFoliosRepetidos)
          ? lowercaseFoliosRepetidos.join(';')
          : lowercaseFoliosRepetidos;
        return (
          (lowercaseRut && lowercaseRut.includes(searchValue)) ||
          (typeof totalBD === 'number' && totalBD === numericSearchValue) ||
          (typeof totalS3 === 'number' && totalS3 === numericSearchValue) ||
          (typeof diferencia === 'number' && diferencia === numericSearchValue) ||
          (typeof foliosNoCargadosString === 'string' && foliosNoCargadosString.includes(searchValue)) ||
          (typeof foliosRepetidosString === 'string' && foliosRepetidosString.includes(searchValue))
        );
      });
      console.log('aux: ', aux);
      setDataFiltered(aux);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };

  console.log(' RR6 Lectura resetModal');

  const resetModal = () => {
    handleClose()
  };

  console.log(' RR7 Lectura handleClose');

  const handleClose = () => {
    setStartDate(new Date().toISOString().slice(0, 10));
    window.location.reload();
  };

  console.log(' RR11 Lectura paginationComponentOptions');

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  };

  console.log(' RR9 Lectura actionsMemo');

  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        setShowLoading(true);
        setLoadingDetail(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('Exportando Datos...');
        const timestamp = new Date().toISOString().replace(/:/g, "-");
        const filename = `export_ResultadosCheckDocumentosBD/S3_${timestamp}.csv`;
        // if (dataFiltered.length === 0) {
        //   setLoadingDetail(false);
        //   setModalTitleLoading('C2C BOLETAS CLOUD');
        //   setLoadingMessage('No hay datos para exportar');
        // } else {
        //   downloadCSV(dataFiltered, filename);
        //   setLoadingDetail(false);
        //   setModalTitleLoading('C2C BOLETAS CLOUD');
        //   setLoadingMessage('Datos Exportados, documento generado: ' + filename);
        // }
      }
    }),
    [dataFiltered]
  );

  const handleClean = () => {
    setStartDate("");
    setEndDate("");
    setTipodocumento("");
    setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(false);
  };

  const handleChangeTipoDocumento = (event) => {
    const selectedValue = event.target.value;
    setTipodocumento(selectedValue);
  };

  const HandleChaangeStartDate = (event) => {
    const selectedValue = event.target.value;
    setStartDate(selectedValue);
  };

  const HandleChaangeEndDate = (event) => {
    const selectedValue = event.target.value;
    setEndDate(selectedValue);
  };

  return (
    <div>
      {showContainerCheckDocsBDS3DesdeHasta && (
        <ContainerCheckDocsBDS3DesdeHasta
          showContainerCheckDocsBDS3DesdeHasta={showContainerCheckDocsBDS3DesdeHasta.toString()}
          showTitle={"Check Documentos entre BD / S3"}
          lastcheck={data.lastcheck}
          tipodocumento={tipodocumento}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleChangeTipoDocumento={handleChangeTipoDocumento}
          HandleChaangeEndDate={HandleChaangeEndDate}
          HandleChaangeStartDate={HandleChaangeStartDate}
          // handleOnChangeFilter={handleOnChangeFilter}
          busqueda={busqueda}
          handleClean={handleClean}
        />
      )}
      <div></div>
      {showContainerResultadosCheckDocsBDS3FechaDesdeHasta && (
        <ContainerResultadosCheckDocsBDS3FechaDesdeHasta
          showContainerResultadosCheckDocsBDS3FechaDesdeHasta={showContainerResultadosCheckDocsBDS3FechaDesdeHasta.toString()}
          showTitle="Resultados Check Documentos BD/S3"
          columns={columns}
          filter={filter}
          handleOnChangeFilterResultados={handleOnChangeFilterResultados}
          actionsMemo={actionsMemo}
          fixedHeader={true}
          // tableData={tableData}        
          // data={dataFiltered}
          tableData={tableData}
          paginationComponentOptions={paginationComponentOptions}
        />
      )}
      {showErrorModal && (
        <ModalError
          showTitle="Ha ocurrido un error"
          showErrorModal={showErrorModal}
          errorMessage={errorMessage}
          resetModal={resetModal}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
    </div>
  )
};

export default CheckDocsBDS3DesdeHasta;
