import apiurl from './apiurl';

//import React from 'react'
const axios = require('axios');


const obj= {
    getDevices: async (idToken) =>        
    axios({
        'method':'GET',
        'url':apiurl +'monitor',
        'headers': {
            'content-type':'application/json',
            'Authorization':"Bearer "+idToken
        },
        'crossdomain': true,
    })
    
};

export default obj;

    
  