import { dataContext } from "components/dataContext/dataContext";
import { AccountContext } from 'components/Account/Account';
import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import '../assets/css/dataTable.css';
import checkDocumentosCargadosFechaDesdeHasta from 'api/checkDocumentosCargadosFechaDesdeHasta';
import downloadCSV from '../csv/downloadCSV';
import ContainerTotalesBDS3DesdeHasta from 'components/Container/ContainerTotalesBDS3DesdeHasta';
import ContainerResultadosCheckDocsBDS3FechaDesdeHasta from 'components/Container/ContainerResultadosCheckDocsBDS3FechaDesdeHasta';
import ContainerCheckDocsBDS3DesdeHasta from 'components/Container/ContainerCheckDocsBDS3DesdeHasta';
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';

function CheckDocsComparacion() {

  const { data } = useContext(dataContext);
  const { getSession } = useContext(AccountContext);
  const [showContainerCheckDocsBDS3DesdeHasta, setShowContainerCheckDocsBDS3DesdeHasta] = useState(true);
  const [tipodocumento, setTipodocumento] = useState('39');
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [filtered, setFiltered] = useState(null)//Filtro para eliminar elementos nulos y asignar solo atributos vistos en tabla
  const [filter, setFilter] = useState('');//value input
  const [dataFiltered, setDataFiltered] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [modalTitleLoading, setModalTitleLoading] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showContainerResultadosCheckDocsBDS3FechaDesdeHasta, setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta] = useState(false);
  const [showContainerTotalesBDS3DesdeHasta, setShowContainerTotalesBDS3DesdeHasta] = useState(false);
  const [tableData, setTableData] = useState(dataFiltered);
  const [dataFilteredTotales, setDataFilteredTotales] = useState([]);
  const [tableDataTotales, setTableDataTotales] = useState([]);
  const fixedHeader = true;

  let today = new Date();
  let miliseconds = today.getTime() - 24 * 60 * 60 * 1000;//restar 2 días
  let yesterday = new Date(miliseconds);

  useEffect(() => {
    if (!dataFiltered || dataFiltered.length === 0) {
      const dataDefaultSinDatosDataFiltered = {
        rut: 'No existen Registros de Rut en S3',
        tipodocumento: 'No existen Tipos de Documentos',
        fechaCarga: 'No existen Documentos Cargados en S3',
        filteredFolios: 'No existen Folios en S3',
        nombreEmpresa: 'No existen Empresas en S3'
      };
      setTableData([dataDefaultSinDatosDataFiltered]);
      return;
    } else {
      const rows = dataFiltered.map((item) => {
        return {
          rut: item.rut,
          tipodocumento: item.tipodocumento,
          fechaCarga: item.fechaCarga,
          filteredFolios: item.filteredFolios,
          nombreEmpresa: item.nombreEmpresa
        };
      });
      setTableData(rows);
    }
  }, [dataFiltered, data]);

  useEffect(() => {
    const stateValues = ['setTableData'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered]);

  useEffect(() => {
    if (!dataFilteredTotales || dataFilteredTotales.length === 0) {
      const dataDefaultSinDatosDataFilteredTotales = {
        cantidadRutsDistintos: 0,
        cantidadFolios: 0,
        cantidadFechasDistintas: 0,
        tiposDocumentosDistintos: 0,
      };
      setTableDataTotales([dataDefaultSinDatosDataFilteredTotales]);
      return;
    } else {
      const rows = dataFilteredTotales.map((item) => {
        return {
          cantidadRutsDistintos: item.cantidadRutsDistintos,
          cantidadFolios: item.cantidadFolios,
          cantidadFechasDistintas: item.cantidadFechasDistintas,
          tiposDocumentosDistintos: item.tiposDocumentosDistintos,
        };
      });
      setTableDataTotales(rows);
    }
  }, [dataFilteredTotales]);

  useEffect(() => {
    const stateValues = ['setTableDataTotales'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFilteredTotales, dataFiltered]);

  useEffect(() => {
    const stateValues = ['startDate', 'endDate'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    const firstDayCurrentMonth = getFirstDayOfMonth(
      yesterday.getFullYear(),
      yesterday.getMonth(),
    );
    setStartDate(firstDayCurrentMonth.toISOString().slice(0, 10));
    setEndDate(yesterday.toISOString().slice(0, 10));
  }, [])

  const columns = [
    {
      name: 'Rut Empresa',
      selector: (row) => row.rut,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Nombre Empresa',
      selector: (row) => row.nombreEmpresa,
      sortable: true,
      wrap: true,
      width: "20%"
    },
    {
      name: 'Fecha Carga',
      selector: (row) => row.fechaCarga,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Tipo Documento',
      selector: (row) => row.tipodocumento,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Folios No Cargados en BD',
      selector: (row) => row.filteredFolios,
      sortable: true,
      wrap: true,
      width: "20%"
    }
  ];

  const columnsTotales = [
    {
      name: 'total DOCUMENTOS con Problemas',
      selector: (row) => row.cantidadFolios,
      sortable: true,
      wrap: true,
      width: "20%"
    },
    {
      name: 'total RUT con Problemas',
      selector: (row) => row.cantidadRutsDistintos,
      sortable: true,
      wrap: true,
      width: "20%"
    },
    {
      name: 'total TIPO DOCUMENTOS con Problemas',
      selector: (row) => row.tiposDocumentosDistintos,
      sortable: true,
      wrap: true,
      width: "20%"
    },
    {
      name: 'total FECHAS con Problemas',
      selector: (row) => row.cantidadFechasDistintas,
      sortable: true,
      wrap: true,
      width: "20%"
    }
  ];

  const busqueda = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      setShowErrorModal(true);
      setErrorMessage('Por favor, ingresa las fechas antes de realizar la búsqueda.');
      return;
    }
    setShowLoading(true);
    setModalTitleLoading('Consulta Comparación de Documentos BD/S3');
    setLoadingDetail(true);
    setLoadingMessage(' Buscando Diferencias...');
    const params = { startDate, endDate, tipodocumento };
    const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
    try {
      let response = await checkDocumentosCargadosFechaDesdeHasta(idToken, params);
      if (response) {
        if (response.status === 200) {
          if (Object.keys(response.data).length === 0) {
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('No existen datos que Comparar en la fecha seleccionada');
            setShowLoading(true);
            return;
          } else {
            setShowLoading(false)
            setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(true);
            setShowContainerTotalesBDS3DesdeHasta(true);
            dataCheck(response.data);
            return;
          }
        } else if (response.status >= 400 && response.status <= 504) {
          setShowLoading(false);
          setShowErrorModal(true);
          const errorMessage = errorMessage[response.status] || 'Error en el Status de respuesta';
          setErrorMessage(`${errorMessage}: ${response.message}`);
        }
      }
    } catch (error) {
      console.log(error);
      setLoadingMessage('Error', error);
      setLoadingDetail(false);
    }
  };

  const dataCheck = (response) => {
    try {
      let groupedData = {}; // Objeto para agrupar los datos por combinación de rut, tipodocumento y fechaCarga

      response.forEach((element) => {

        const { fechaCarga, folios, nombreEmpresa, rut, tipodocumento } = element;

        const key = `${rut}_${tipodocumento}_${fechaCarga}`; // Creamos una clave única para agrupar los datos
        if (!groupedData[key]) {
          // Si no existe la clave, creamos un nuevo objeto
          groupedData[key] = {
            nombreEmpresa: nombreEmpresa,
            rut: rut,
            tipodocumento: tipodocumento,
            fechaCarga: fechaCarga,
            filteredFolios: [],
          };
        }
        groupedData[key].filteredFolios.push(...folios); // Agregamos los folios a la lista correspondiente

      });

      const cantidadFolios = Object.values(groupedData).reduce((total, obj) => total + obj.filteredFolios.length, 0);

      const cantidadRutsDistintos = new Set(Object.values(groupedData).map((obj) => obj.rut)).size;

      const rows = Object.values(groupedData);

      const datosFiltradosDocFechaDesdeHasta = rows.map((row) => {
        const filteredFolios = row.filteredFolios.map(item => `(${item})`).join('\n');

        return {
          fechaCarga: row.fechaCarga,
          nombreEmpresa: row.nombreEmpresa,
          rut: row.rut,
          tipodocumento: row.tipodocumento,
          filteredFolios: filteredFolios,
        };
      });

      const cantidadFechasDistintas = new Set(Object.values(groupedData).map((obj) => obj.fechaCarga)).size;
      const tiposDocumentosDistintos = new Set(Object.values(groupedData).map((obj) => obj.tipodocumento)).size;
      const tableDataTotales = [
        {
          cantidadRutsDistintos: cantidadRutsDistintos,
          cantidadFolios: cantidadFolios,
          cantidadFechasDistintas: cantidadFechasDistintas,
          tiposDocumentosDistintos: tiposDocumentosDistintos
        }
      ];

      setDataFiltered(datosFiltradosDocFechaDesdeHasta);
      setDataFilteredTotales(tableDataTotales)
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
    } catch (error) {
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
      setShowErrorModal(true);
      setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
    }
  };

  const handleOnChangeFilterResultados = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.trim().toLowerCase(); // Convertimos el valor de búsqueda a minúsculas
    const numericSearchValue = Number(searchValue);
    try {
      const aux = filtered.filter((element) => {
        const {
          rut,
          totalBD,
          totalS3,
          diferencia,
          foliosNoCargadosEnBD,
          foliosRepetidos
          // foliosNoCargadosEnBD
        } = element;
        // Convertir los campos a minúsculas antes de comparar
        const lowercaseRut = rut && rut.trim().toLowerCase();
        const lowercaseTotalBD = totalBD && totalBD.trim().toLowerCase();
        const lowercaseTotalS3 = totalS3 && totalS3.trim().toLowerCase();
        const lowercaseDiferencia = diferencia && diferencia.trim().toLowerCase();
        const lowercaseFoliosNoCargadosEnBD = foliosNoCargadosEnBD && foliosNoCargadosEnBD.trim().toLowerCase();
        const lowercaseFoliosRepetidos = foliosRepetidos && foliosRepetidos.trim().toLowerCase();
        // lowercaseFoliosNoCargadosEnBD = Array.isArray(foliosNoCargadosEnBD)
        //   ? foliosNoCargadosEnBD.map(item => item.toLowerCase())
        //   : foliosNoCargadosEnBD && foliosNoCargadosEnBD.toLowerCase();
        // const lowercasefoliosRepetidos = Array.isArray(foliosRepetidos)
        //   ? foliosRepetidos.map(item => item.toLowerCase())
        //   : foliosRepetidos && foliosRepetidos.toLowerCase();
        // Verificar si foliosNoCargadosEnBD es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosNoCargadosString = Array.isArray(lowercaseFoliosNoCargadosEnBD)
          ? lowercaseFoliosNoCargadosEnBD.join(';')
          : lowercaseFoliosNoCargadosEnBD;
        // Verificar si foliosRepetidosCantidad es un arreglo y convertirlo a una cadena separada por comas y en minúsculas
        const foliosRepetidosString = Array.isArray(lowercaseFoliosRepetidos)
          ? lowercaseFoliosRepetidos.join(';')
          : lowercaseFoliosRepetidos;
        return (
          (lowercaseRut && lowercaseRut.includes(searchValue)) ||
          (typeof totalBD === 'number' && totalBD === numericSearchValue) ||
          (typeof totalS3 === 'number' && totalS3 === numericSearchValue) ||
          (typeof diferencia === 'number' && diferencia === numericSearchValue) ||
          (typeof foliosNoCargadosString === 'string' && foliosNoCargadosString.includes(searchValue)) ||
          (typeof foliosRepetidosString === 'string' && foliosRepetidosString.includes(searchValue))
        );
      });
      console.log('aux: ', aux);
      setDataFiltered(aux);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };

  console.log(' RR6 Lectura resetModal');

  const resetModal = () => {
    handleClose()
  };

  console.log(' RR7 Lectura handleClose');

  const handleClose = () => {
    setStartDate(new Date().toISOString().slice(0, 10));
    window.location.reload();
  };

  console.log(' RR11 Lectura paginationComponentOptions');

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  };

  console.log(' RR9 Lectura actionsMemo');

  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        setShowLoading(true);
        setLoadingDetail(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('Exportando Datos...');
        const timestamp = new Date().toISOString().replace(/:/g, "-");
        const filename = `export_ResultadosCheckDocumentosBD/S3_${timestamp}.csv`;
        if (dataFiltered.length === 0) {
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('No hay datos para exportar');
        } else {
          downloadCSV(dataFiltered, filename);
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('Datos Exportados, documento generado: ' + filename);
        }
      }
    }),
    [dataFiltered]
  );

  const handleClean = () => {
    setStartDate("");
    setEndDate("");
    setTipodocumento("");
    setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(false);
    setShowContainerTotalesBDS3DesdeHasta(false)
  };

  const handleChangeTipoDocumento = (event) => {
    const selectedValue = event.target.value;
    setTipodocumento(selectedValue);
  };

  const HandleChaangeStartDate = (event) => {
    const selectedValue = event.target.value;
    setStartDate(selectedValue);
  };

  const HandleChaangeEndDate = (event) => {
    const selectedValue = event.target.value;
    setEndDate(selectedValue);
  };

  return (
    <div>
      {showContainerCheckDocsBDS3DesdeHasta && (
        <ContainerCheckDocsBDS3DesdeHasta
          showContainerCheckDocsBDS3DesdeHasta={showContainerCheckDocsBDS3DesdeHasta.toString()}
          showTitle={"Filtro Rango Fecha Check Diferencias BD/S3"}
          lastcheck={data.lastcheck}
          tipodocumento={tipodocumento}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleChangeTipoDocumento={handleChangeTipoDocumento}
          HandleChaangeEndDate={HandleChaangeEndDate}
          HandleChaangeStartDate={HandleChaangeStartDate}
          busqueda={busqueda}
          handleClean={handleClean}
        />
      )}
      <div></div>
      {showContainerTotalesBDS3DesdeHasta && (
        <ContainerTotalesBDS3DesdeHasta
          showContainerTotalesBDS3DesdeHasta={showContainerTotalesBDS3DesdeHasta.toString()}
          showTitle={"Totalizadores Rango Fecha Check Diferencias BD/S3"}
          fixedHeader={fixedHeader}
          columnsTotales={columnsTotales}
          paginationComponentOptions={paginationComponentOptions}
          tableDataTotales={tableDataTotales}
        />
      )}
      <div></div>
      {showContainerResultadosCheckDocsBDS3FechaDesdeHasta && (
        <ContainerResultadosCheckDocsBDS3FechaDesdeHasta
          showContainerResultadosCheckDocsBDS3FechaDesdeHasta={showContainerResultadosCheckDocsBDS3FechaDesdeHasta.toString()}
          showTitle="Detalle Resultados Check Diferencias BD/S3"
          columns={columns}
          filter={filter}
          handleOnChangeFilterResultados={handleOnChangeFilterResultados}
          actionsMemo={actionsMemo}
          fixedHeader={true}
          tableData={tableData}
        />
      )}
      <div></div>
      {showErrorModal && (
        <ModalError
          showTitle="Ha ocurrido un error"
          showErrorModal={showErrorModal}
          errorMessage={errorMessage}
          resetModal={resetModal}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
    </div>
  )
};

export default CheckDocsComparacion;