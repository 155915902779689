
import UploadXMl from "api/uploadXML";
import { AccountContext } from 'components/Account/Account';
import { useContext, useEffect, useMemo, useState,useRef } from "react";
import Select from 'react-select';
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";


function UploadXMLCAF () {

  
  const [modalDescarga, setModalDescarga] = useState(false);
  const [loading, setloading] = useState(true);
  const [modalMensaje, setmodalMensaje] = useState('');
  const [errorUpload, seterrorUpload] = useState(false);
  const {user,getSession} = useContext(AccountContext);
  const [jsonResponse, setjsonResponse] = useState({})
  

  const closeModal = () =>{
    setModalDescarga(false);
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

    const handleClick = event => {
        hiddenFileInput.current.click();
      };  

    const hiddenFileInput = useRef(null);

    const getBinaryFromFile = async(file) =>{
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
  
          reader.addEventListener("load", () => resolve(reader.result));
          reader.addEventListener("error", err => reject(err));
  
          reader.readAsText(file, 'UTF-8');
      });
    }

    const handleChange = async(event) => {

      
      const fileUploaded = event.target.files[0];
      setModalDescarga(true);
      setloading(true);
      seterrorUpload(false);

      // console.log(fileUploaded)
     
      // return true;

      try {
        const binary = await getBinaryFromFile(fileUploaded);
        await sleep(2000);
        // const body = { filename: fileUploaded.name, data: binary };
        // console.log(body);
        const idToken= await getSession().then((session) => {return session.idToken.jwtToken});
        const response = await UploadXMl(binary,idToken);
        
        setmodalMensaje('CAF subido correctamente')
        setjsonResponse(response.data)
        console.log(response);
        seterrorUpload(false);
        setloading(false);
        // setModalDescarga(false)

       
      } catch (error) {
        setloading(false);
        seterrorUpload(true);
        if(error.response.data.message=='Caf duplicado'){
          setmodalMensaje('CAF duplicado');
        }
        else{
          setmodalMensaje('Error al insertar CAF');
        }
        
        // console.log(error);
      }

      
      hiddenFileInput.current.value = null;
      
    }

    const paginationOpciones = {
      rowsPerPageText: 'Filas por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsText: 'Todos'
    }
    

  return (
    <>
      <Container fluid>
       
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Subir CAF</Card.Title>
              </Card.Header>
              <Card.Body>
                <Col className="mt-3 text-nowrap">
                  <Button onClick={handleClick}>Subir archivo</Button>
                  <input
                    type="file"
                    accept=".xml"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        
      </Container>

      <Modal show={modalDescarga}>
        <Modal.Header>
          {loading && <Modal.Title>Subiendo CAF</Modal.Title>}
          {!loading && <Modal.Title>{modalMensaje}</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {/* {!loading && <h2>{modalMensaje}</h2>} */}
          {!errorUpload && !loading && (
            <div>
              <p>Rut: {jsonResponse.rut} </p>
              <p>Folio inicio: {jsonResponse.min_folio} </p>
              <p>Folio fin: {jsonResponse.max_folio} </p>
              <p>Tipo documento: {jsonResponse.tipodocumento} </p>
            </div>
          )}
          {loading && (
            <Spinner
              animation="border"
              role="status"
              className="sticky-top"
            ></Spinner>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!loading && (
            <>
              <Button variant="secondary" onClick={closeModal}>
                Close
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}


export default UploadXMLCAF;