function convertArrayOfObjectsToCSV(array) {

  array=array.sort(function (a, b) {
      if (a.empresa > b.empresa) {
        return 1;
      }
      if (a.empresa < b.empresa) {
        return -1;
      }
      return 0;
    });
  let result;  
  const columnDelimiter = ',';  
  const lineDelimiter = '\n';  
  const keys = Object.keys(array[0]);
  result = '';  
  result += keys.join(columnDelimiter);  
  result += lineDelimiter;  
  array.forEach(item => {  
    let ctr = 0;  
    keys.forEach(key => {  
      if (ctr > 0) result += columnDelimiter;
      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });
  return result;

}

// export default function downloadCSV(array) {

//     const link = document.createElement('a');
//     let csv = convertArrayOfObjectsToCSV(array);
//     if (csv == null) return;
//     const filename = 'export.csv';
//     csv = "\ufeff"+csv;
  
//     if (!csv.match(/^data:text\/csv/i)) {
//       csv = `data:text/csv;charset=utf-8,${csv}`;
//     }
//     link.setAttribute('href', encodeURI(csv));
//     link.setAttribute('download', filename);
//     link.click();
//   }

export default function downloadCSV(array, filename = null) {
const link = document.createElement('a');
let csv = convertArrayOfObjectsToCSV(array);
if (csv == null) return;

const timestamp = new Date().toISOString().replace(/:/g, "-");
if (!filename) {
  filename = `export_${timestamp}.csv`;
}
csv = "\ufeff" + csv;
if (!csv.match(/^data:text\/csv/i)) {
  csv = `data:text/csv;charset=utf-8,${csv}`;
}
link.setAttribute('href', encodeURI(csv));
link.setAttribute('download', filename);
link.click();
}
