import apiurl from './apiurl';
const axios = require('axios');

const getDevicesByTenant=  async (idToken,tenant_id)  =>{

    const params = {tenant_id:tenant_id};

    return new Promise(async(resolve, reject) => {

        // resolve(res);
        // return true;
        try {
            
            const result = await axios
            .get(apiurl+"devices", 
            {
            'headers': {
                "Content-Type": "application/json",
                'Authorization':"Bearer " + idToken
            },
            'params': {
                ...params
            },
            'crossdomain': true,
            })
            // console.log(result);
            resolve(result)
                        
            
            
        } catch (error) {
            
            console.log(error);
            reject(error)
            
        }
    })
    
};

export default getDevicesByTenant;