import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useState } from "react"
import { Button, Card, Collapse, Nav } from "react-bootstrap";


function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const [open, setOpen] = useState(false)
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

 
  return (
    <div className="sidebar" data-image={image} data-color={color}>
      <div
        className="sidebar-background"
        style={{
          backgroundImage: "url(" + image + ")",
        }}
      />
      <div className="sidebar-wrapper">
        <div className="logo d-flex align-items-center justify-content-start">
         
            <div className="logo-img">
              <img
                src={require("assets/img/c2cLogo.png").default}
                alt="..."
              />
            </div>
          
          <a className="simple-text" href="#">
               Monitor 
          </a>
        </div>
       
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect){

              if (prop.childrens) {
                // console.log('asdas');
                return(<div className={open ? "sidebar-item open" : "sidebar-item"}>
                <div className="sidebar-title">
                <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      
                      <p>{prop.name}</p>
                      <i className={prop.icon} />
                      <i className="bi-chevron-down toggle-btn" onClick={() => setOpen(!open)}></i>
                    </NavLink>
                  </li>
                   
                </div>
                <div className="sidebar-content">
                    { prop.childrens.map((child, index) => console.log(child)) }
                </div>
            </div>)
                
              }
              else{
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              }
            
            }
             
            return null;
          })}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
