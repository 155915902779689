import React, {createContext, useEffect, useReducer} from 'react'

import {CognitoUser, AuthenticationDetails} from 'amazon-cognito-identity-js'
import Pool from '../../UserPool'
import { authReducer } from './authReducer';


const AccountContext = createContext();

const Account = (props) => {


    const init = () =>{

        return JSON.parse(localStorage.getItem('user')) || {logged:false};
    }

    const [user, dispatch] = useReducer(authReducer, {}, init);

    useEffect(() => {
        localStorage.setItem('user',JSON.stringify(user));
    }, [user])

    const getSession = async() =>{
        return await new Promise((resolve, reject) =>{

            // console.log('GetSession')

            const user = Pool.getCurrentUser();
            
            if (user) {
                user.getSession((err, session) => {
                    // console.log(session.isValid());
                    if(err){
                        reject(false);
                    }
                    else{
                        resolve(session);
                    }
                })
            }
            else{
                resolve (false);
            }
        })
    }

    const authenticate = async (Username, Password) => {
        return await new Promise((resolve, reject) => {

            const user = new CognitoUser({Username, Pool});
            
            const authDetails = new AuthenticationDetails({Username,Password});
    
            user.authenticateUser(authDetails,{
                onSuccess: (data) => {
                    console.log("onSuccess: ", data.getAccessToken().getJwtToken());
                    resolve(data);
                },
                onFailure: (err) => {
                    console.log("onFailure: ", err);
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    console.log("newPasswordRequired: ", data);
                    resolve(data);
                }
            })
        })
    }

    const logout = () => {
        console.log(user)
        console.log('logout');
        const user = Pool.getCurrentUser();
        if(user){
            user.globalSignOut();
        }
    }

    return (
        <AccountContext.Provider value ={{authenticate,getSession, logout,user,dispatch}}>
            {props.children}
        </AccountContext.Provider>
    );

};

export {Account, AccountContext};


    

