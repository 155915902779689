import React, { useContext } from "react";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";
import { AccountContext } from '../Account/Account'
import { useHistory } from 'react-router';
import { types } from "types/types";

function Header() {

  const {dispatch} = useContext(AccountContext);

  const history = useHistory();

  const finishSession =async () => {

    console.log('logout')
    const action ={
      type: types.logout,
      
    }
    dispatch(action);

    // history.push('/login');
    
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" navbar>
             <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#"
                onClick={(e) => e.preventDefault()}
              >
                <span className="no-icon" onClick={finishSession} >Cerrar sesión</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
