import { dataContext } from "components/dataContext/dataContext";
import { AccountContext } from 'components/Account/Account';
import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import '../assets/css/dataTable.css';
import downloadCSV from '../csv/downloadCSV';
// import ContainerTotalesBDS3DesdeHasta from 'components/Container/ContainerTotalesBDS3DesdeHasta';
import ContainerResultadosCheckDocsBDS3FechaDesdeHasta from 'components/Container/ContainerResultadosCheckDocsBDS3FechaDesdeHasta';
import ContainerCheckDocsBDS3DesdeHasta from 'components/Container/ContainerCheckDocsBDS3DesdeHasta';
import ModalCargando from 'components/Modal/ModalCargando';
import ModalError from 'components/Modal/ModalError';
import checkDocumentosCargadosDesdeHasta from 'api/checkDocumentosCargadosDesdeHasta';

function CheckDocumentosCargadosDesdeHasta() {

  const { data } = useContext(dataContext);
  const { getSession } = useContext(AccountContext);
  const [showContainerCheckDocsBDS3DesdeHasta, setShowContainerCheckDocsBDS3DesdeHasta] = useState(true);
  const [tipodocumento, setTipodocumento] = useState('39');
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [filter, setFilter] = useState('');
  const [filtered, setFiltered] = useState(null);
  const [dataFiltered, setDataFiltered] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [modalTitleLoading, setModalTitleLoading] = useState('');
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showContainerResultadosCheckDocsBDS3FechaDesdeHasta, setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta] = useState(false);
  const [showContainerTotalesBDS3DesdeHasta, setShowContainerTotalesBDS3DesdeHasta] = useState(false);
  const [tableData, setTableData] = useState(dataFiltered);
  const [dataFilteredTotales, setDataFilteredTotales] = useState([]);
  const [tableDataTotales, setTableDataTotales] = useState([]);

  const fixedHeader = true;

  let today = new Date();
  let miliseconds = today.getTime() - 24 * 60 * 60 * 1000;
  let yesterday = new Date(miliseconds);

  useEffect(() => {
    if (!dataFiltered || dataFiltered.length === 0) {
      const dataDefaultSinDatosDataFiltered = {
        tenantID: 'SIN REGISTROS',
        rut: 'SIN REGISTROS',
        nombreEmpresa: 'SIN REGISTROS',
        fecha: 'SIN REGISTROS',
        tipodocumento: 'SIN REGISTROS',
        folios: 'SIN REGISTROS',
        keyDocumentoS3: 'SIN REGISTROS',

      };
      setTableData([dataDefaultSinDatosDataFiltered]);
      return;
    } else {
      const rows = dataFiltered.map((item) => {
        return {
          tenantID: item.tenantID,
          rut: item.rut,
          nombreEmpresa: item.nombreEmpresa,
          fecha: item.fecha,
          tipodocumento: item.tipodocumento,
          folios: item.folios,
          keyDocumentoS3: item.keyDocumentoS3
        };
      });
      setTableData(rows);
    }
  }, [dataFiltered, data]);

  useEffect(() => {
    const stateValues = ['setTableData'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [dataFiltered]);

  useEffect(() => {
    const stateValues = ['startDate', 'endDate'];
    stateValues.forEach((state) => {
      console.log(`RR useEffect ${state}:`, eval(state));
    });
  }, [startDate, endDate]);

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    const firstDayCurrentMonth = getFirstDayOfMonth(
      yesterday.getFullYear(),
      yesterday.getMonth(),
    );
    setStartDate(firstDayCurrentMonth.toISOString().slice(0, 10));
    setEndDate(yesterday.toISOString().slice(0, 10));
  }, [])

 const busqueda = async (e) => {
    e.preventDefault();
    if (!startDate || !endDate) {
      setShowErrorModal(true);
      setErrorMessage('Por favor, ingresa las fechas antes de realizar la búsqueda.');
      return;
    }
    setShowLoading(true);
    setModalTitleLoading('Consulta Comparación de Documentos');
    setLoadingDetail(true);
    setLoadingMessage(' Buscando Diferencias...');
    const params = { startDate, endDate, tipodocumento };
    const idToken = await getSession().then((session) => session.idToken.jwtToken);
    try {
      let response = await checkDocumentosCargadosDesdeHasta(idToken, params);
      if (response) {
        if (response.status === 200) {
          let responseData = response.data;
          if (responseData.data === undefined || Object.keys(responseData.data).length === 0) {
            // if (responseData.data.respuestaComparacionBDS3 === undefined || Object.keys(responseData.data.respuestaComparacionBDS3).length === 0) {
            setLoadingDetail(false);
            setModalTitleLoading('C2C BOLETAS CLOUD');
            setLoadingMessage('No existen datos que Comparar en la fecha seleccionada');
            return;
          } else {
            setShowLoading(false)
            setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(true);
            // setShowContainerTotalesBDS3DesdeHasta(true);
            dataCheck(responseData.data);
            return;
          }
        } else if (response.status >= 400 && response.status <= 504) {
          setShowLoading(false);
          setShowErrorModal(true);
          const errorMessage = errorMessage[response.status] || 'Error en el Status de respuesta';
          setErrorMessage(`${errorMessage}: ${response.message}`);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingMessage('Error', error);
      setLoadingDetail(false);
    }
  };

  const dataCheck = (response) => {
    try {

      const soloRut = Object.keys(response["foliosNoEncontrados"]);
      let filteredData = [];
      for (const rut of soloRut) {
          const empresa = response["foliosNoEncontrados"][rut];
          const nombreEmpresa = empresa.name;
          const tenantID = empresa.tenant_id;

          for (const tipoDocumento in empresa) {
              if (tipoDocumento !== "name" && tipoDocumento !== "tenant_id") {
                  for (const fechaDocumento in empresa[tipoDocumento]) {
                      const foliosAgrupados = empresa[tipoDocumento][fechaDocumento].foliosAgrupados;
                      const keysAgrupadas = empresa[tipoDocumento][fechaDocumento].keysAgrupadas;
                      for (let i = 0; i < foliosAgrupados.length; i++) {
                        filteredData.push({
                          tenantID: empresa.tenant_id,
                          rut: rut,
                          nombreEmpresa: empresa.name,
                          fecha: fechaDocumento,
                          tipodocumento: tipoDocumento,
                          folios: foliosAgrupados,
                          keyDocumentoS3: keysAgrupadas,
                            });
                      }
                  }
              }
          }
      }
      setDataFiltered(filteredData);
      setFiltered(filteredData);
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
    } catch (error) {
      setShowLoading(false);
      setLoadingDetail(false);
      setModalTitleLoading('');
      setLoadingMessage('');
      setShowErrorModal(true);
      setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
    }
  };

  const columns = [
    {
      name: 'ID Tenant',
      selector: (row) => row.tenantID,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Rut Empresa',
      selector: (row) => row.rut,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Nombre Empresa',
      selector: (row) => row.nombreEmpresa,
      sortable: true,
      wrap: true,
      width: "20%"
    },
    {
      name: 'Fecha Documento Carga S2',
      selector: (row) => row.fecha,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Tipo Documento',
      selector: (row) => row.tipodocumento,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Folios No Cargados en BD',
      selector: (row) => row.folios,
      sortable: true,
      wrap: true,
      width: "10%"
    },
    {
      name: 'Clave Objetos S3',
      selector: (row) => row.keyDocumentoS3,
      sortable: true,
      wrap: true,
      width: "40%"
    }
  ];

  const handleOnChangeFilterResultados = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
    const searchValue = e.target.value.toLowerCase(); // Convertimos el valor de búsqueda a minúsculas

    try {
      if (!Array.isArray(filtered) || typeof setDataFiltered !== 'function') {
        setShowErrorModal(true);
        setErrorMessage('Error: filtered o setDataFiltered no están definidos correctamente.');
        return;
      }
      const aux = filtered.filter((element) => {
        const {
          tenantID,
          rut,
          nombreEmpresa,
          fecha,
          tipodocumento,
          filteredFolios,
          keyDocumentoS3
        } = element;
        return (
          (tenantID && tenantID.toLowerCase().trim().includes(searchValue)) ||
          (rut && rut.trim().includes(searchValue)) ||
          (nombreEmpresa && nombreEmpresa.toLowerCase().trim().includes(searchValue)) ||
          (fecha && fecha.toLowerCase().trim().includes(searchValue)) ||
          (tipodocumento && tipodocumento.toLowerCase().trim().includes(searchValue)) ||
          (filteredFolios && filteredFolios.toLowerCase().trim().includes(searchValue)) ||
          (keyDocumentoS3 && keyDocumentoS3.toLowerCase().trim().includes(searchValue)) 
        );
      });
      setTableData(aux);
    } catch (error) {
      setShowErrorModal(true);
      setErrorMessage('Ocurrió un error al filtrar los datos: ' + error.message);
    }
  };

  const resetModal = () => {
    handleClose()
  };

  const handleClose = () => {
    setStartDate(new Date().toISOString().slice(0, 10));
    window.location.reload();
  };

  const paginationComponentOptions = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  };

  const actionsMemo = useMemo(
    () => ({
      onExport: () => {
        setShowLoading(true);
        setLoadingDetail(true);
        setModalTitleLoading('C2C BOLETAS CLOUD');
        setLoadingMessage('Exportando Datos...');
        const timestamp = new Date().toISOString().replace(/:/g, "-");
        const filename = `export_ResultadosCheckDocumentosBD/S3_${timestamp}.csv`;
        if (tableData.length === 0) {
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('No hay datos para exportar');
        } else {
          downloadCSV(tableData, filename);
          setLoadingDetail(false);
          setModalTitleLoading('C2C BOLETAS CLOUD');
          setLoadingMessage('Datos Exportados, documento generado: ' + filename);
        }
      }
    }),
    [tableData]
  );

  const handleClean = () => {
    setStartDate("");
    setEndDate("");
    setTipodocumento("");
    setShowContainerResultadosCheckDocsBDS3FechaDesdeHasta(false);
    // setShowContainerTotalesBDS3DesdeHasta(false)
  };

  const handleChangeTipoDocumento = (event) => {
    const selectedValue = event.target.value;
    setTipodocumento(selectedValue);
  };

  const HandleChaangeStartDate = (event) => {
    const selectedValue = event.target.value;
    setStartDate(selectedValue);
  };

  const HandleChaangeEndDate = (event) => {
    const selectedValue = event.target.value;
    setEndDate(selectedValue);
  };

  return (
    <div>
      {showContainerCheckDocsBDS3DesdeHasta && (
        <ContainerCheckDocsBDS3DesdeHasta
          showContainerCheckDocsBDS3DesdeHasta={showContainerCheckDocsBDS3DesdeHasta.toString()}
          showTitle={"Filtro Rango Fecha Check Diferencias BD/S3"}
          lastcheck={data.lastcheck}
          tipodocumento={tipodocumento}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleChangeTipoDocumento={handleChangeTipoDocumento}
          HandleChaangeEndDate={HandleChaangeEndDate}
          HandleChaangeStartDate={HandleChaangeStartDate}
          busqueda={busqueda}
          handleClean={handleClean}
        />
      )}
      <div></div>
      {/* {showContainerTotalesBDS3DesdeHasta && (
        <ContainerTotalesBDS3DesdeHasta
          showContainerTotalesBDS3DesdeHasta={showContainerTotalesBDS3DesdeHasta.toString()}
          showTitle={"Totalizadores Rango Fecha Check Diferencias BD/S3"}
          fixedHeader={fixedHeader}
          columnsTotales={columnsTotales}
          paginationComponentOptions={paginationComponentOptions}
          tableDataTotales={tableDataTotales}
        />
      )} */}
      <div></div>
      {showContainerResultadosCheckDocsBDS3FechaDesdeHasta && (
        <ContainerResultadosCheckDocsBDS3FechaDesdeHasta
          showContainerResultadosCheckDocsBDS3FechaDesdeHasta={showContainerResultadosCheckDocsBDS3FechaDesdeHasta.toString()}
          showTitle="Detalle Resultados Check Diferencias BD/S3"
          columns={columns}
          filter={filter}
          handleOnChangeFilterResultados={handleOnChangeFilterResultados}
          actionsMemo={actionsMemo}
          fixedHeader={true}
          tableData={tableData}
        />
      )}
      <div></div>
      {showErrorModal && (
        <ModalError
          showTitle="Ha ocurrido un error"
          showErrorModal={showErrorModal}
          errorMessage={errorMessage}
          resetModal={resetModal}
        />
      )}
      {showLoading && (
        <ModalCargando
          modalTitleLoading={modalTitleLoading}
          showLoading={showLoading}
          handleClose={handleClose}
          loadingDetail={loadingDetail}
          loadingMessage={loadingMessage}
        />
      )}
    </div>
  )
};

export default CheckDocumentosCargadosDesdeHasta;



  // useEffect(() => {
  //   if (!dataFilteredTotales || dataFilteredTotales.length === 0) {
  //     const dataDefaultSinDatosDataFilteredTotales = {
  //       cantidadRutsDistintos: 0,
  //       cantidadFolios: 0
  //     };
  //     setTableDataTotales([dataDefaultSinDatosDataFilteredTotales]);
  //     return;
  //   } else {
  //     const rows = dataFilteredTotales.map((item) => {
  //       return {
  //         cantidadRutsDistintos: item.cantidadRutsDistintos,
  //         cantidadFolios: item.cantidadFolios
  //       };
  //     });
  //     setTableDataTotales(rows);
  //   }
  // }, [dataFilteredTotales]);

  // useEffect(() => {
  //   const stateValues = ['setTableDataTotales'];
  //   stateValues.forEach((state) => {
  //     console.log(`RR useEffect ${state}:`, eval(state));
  //   });
  // }, [dataFilteredTotales, dataFiltered]);

   // useEffect(() => {
  //   const stateValues = [
  //     'showLoading',
  //     'loadingDetail',
  //     'loadingMessage',
  //     'showErrorModal',
  //     'modalTitleLoading',
  //     'errorMessage'
  //   ];
  //   stateValues.forEach((state) => {
  //     console.log(`useEffect ${state}:`, eval(state));
  //   });
  // }, [ loadingDetail, loadingMessage, showErrorModal, modalTitleLoading, errorMessage]);
  // }, [showLoading, loadingDetail, loadingMessage, showErrorModal, modalTitleLoading, errorMessage]);

  // const columnsTotales = [
  //   {
  //     name: 'total DOCUMENTOS con Problemas',
  //     selector: (row) => row.cantidadFolios,
  //     sortable: true,
  //     wrap: true,
  //     width: "50%"
  //   },
  //   {
  //     name: 'total RUT con Problemas',
  //     selector: (row) => row.cantidadRutsDistintos,
  //     sortable: true,
  //     wrap: true,
  //     width: "50%"
  //   }
  // ];

  

  // const dataCheck = (response) => {
  //   try {
  //     let searchValue = {};
  //     const rutCount = response["foliosNoEncontrados"]; // Cambiado para que rutCount sea la longitud de 'response'
  //     let foliosCount = 0;

  //     rutCount.forEach(elemento => {
  //       foliosCount += elemento.length;
  //     });

  //     let filteredData = response.flatMap(element => {
  //       console.log(element);
  //       const {
  //         rutEmpresaS3,
  //         nombreEmpresa,
  //         fechasCargasS3,
  //         tipoDocumentoS3,
  //         folioDocumentoS3,
  //         foliosRepetidosS3
  //       } = element;

  //       console.log(filteredData);

  //       // Check if fechas is not empty or undefined
  //       if (fechas && Object.keys(fechas).length > 0) {
  //         return Object.keys(fechas).map(fecha => ({
  //           rut,
  //           nombreEmpresa,
  //           fecha: fecha,
  //           tipodocumento: tipoDocumentoS3,
  //           folios: folios,
  //           foliosRepetidosS3,
  //         }));
  //       } else {
  //         // Handle the case when fechas is empty or undefined
  //         return [];
  //       }
  //     });    
  //     console.log(filteredData);

  //     const tableDataTotales = [
  //       {
  //         cantidadRutsDistintos: rutCount,
  //         cantidadFolios: foliosCount,
  //       }
  //     ];
  //     setDataFilteredTotales(tableDataTotales);
  //     setDataFiltered(filteredData);
  //     setFiltered(filteredData);
  //     setShowLoading(false);
  //     setLoadingDetail(false);
  //     setModalTitleLoading('');
  //     setLoadingMessage('');
  //   } catch (error) {
  //     setShowLoading(false);
  //     setLoadingDetail(false);
  //     setModalTitleLoading('');
  //     setLoadingMessage('');
  //     setShowErrorModal(true);
  //     setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
  //   }
  // };


  // const dataCheck = (response) => {
  //   try {
  //     console.log(response)
  //     let searchValue = {};
  //     const rutCount = Object.keys(response).length
  //     let foliosCount = 0;
  //     response.forEach(elemento => {
  //       foliosCount += elemento.folioDocumentoS3.length;
  //     });
  //     let dataArray = Object.values(response);

  //     let filteredData = [];

  //     if (Array.isArray(dataArray.folioDocumentoS3)) {
  //       filteredData.push({
  //         rut: rutEmpresaS3,
  //         nombreEmpresa: nombreEmpresa,
  //         fecha: null, // Puedes agregar la fecha que desees aquí
  //         tipodocumento: dataArray.tipoDocumentoS3,
  //         folios: dataArray.folioDocumentoS3,
  //         foliosRepetidosS3: dataArray.foliosRepetidosS3["2023-04-04"], // Cambia la fecha si es necesario
  //       });
  //     }

  //     console.log(filteredData);

  //     filteredData = dataArray.flatMap(array => {
  //       if (Array.isArray(array)) {
  //         return array.filter(element => {
  //           const {
  //             rut: rutEmpresaS3,
  //             nombreEmpresa,
  //             fecha: fechaCarga,
  //             tipodocumento: tipoDocumentoS3,
  //             folios: folioDocumentoS3,
  //             // clavesS3Folios,
  //             foliosRepetidosS3,
  //             // clavesS3FoliosRepetidos
  //           } = element;

  //           const searchValue = {
  //             rut: rutEmpresaS3,
  //             nombreEmpresa,
  //             fecha: fechaCarga,
  //             tipodocumento: tipoDocumentoS3,
  //             folios: folioDocumentoS3,
  //             // clavesS3Folios,
  //             foliosRepetidosS3,
  //             // clavesS3FoliosRepetidos
  //           }
  //           return searchValue; // Asegúrate de que esta condición sea correcta
  //         });
  //       } else {
  //         return [];
  //       }
  //     });


  //     console.log('filteredData: ', filteredData)
  //     console.log('searchValue: ', searchValue)

  //     // const foliosRepetidosAgrupados = agruparRegistrosPorFechaYFolio(filteredData);

  //     const totalDocS3DesdeHasta = foliosCount;
  //     const tableDataTotales = [
  //       {
  //         cantidadRutsDistintos: rutCount,
  //         cantidadFolios: totalDocS3DesdeHasta,
  //       }
  //     ];
  //     setDataFiltered(filteredData);
  //     setDataFilteredTotales(tableDataTotales)
  //     setFiltered(filteredData);
  //     setShowLoading(false);
  //     setLoadingDetail(false);
  //     setModalTitleLoading('');
  //     setLoadingMessage('');
  //   } catch (error) {
  //     setShowLoading(false);
  //     setLoadingDetail(false);
  //     setModalTitleLoading('');
  //     setLoadingMessage('');
  //     setShowErrorModal(true);
  //     setErrorMessage("Ocurrió un error al cargar los datos: " + error.message);
  //   }
  // }

  // function agruparRegistrosPorFechaYFolio(objetos) {
  //   const agrupados = {};
  //   objetos.forEach(objeto => {
  //     objeto.foliosRepetidos.forEach(registro => {
  //       const partes = registro.split(',');
  //       const fecha = partes[0];
  //       const folio = partes[1];
  //       const tipo = partes[2];
  //       if (fecha && folio && tipo) {
  //         const clave = `${fecha}-${folio}-${tipo}`;
  //         if (!agrupados[clave]) {
  //           agrupados[clave] = [];
  //         }
  //         agrupados[clave].push(objeto);
  //       }
  //     });
  //   });

  //   return Object.values(agrupados).map(grupo => {
  //     return {
  //       fecha: grupo[0].fecha,
  //       folio: grupo[0].foliosRepetidos[0].split(',')[1],
  //       tipo: grupo[0].foliosRepetidos[0].split(',')[2],
  //       registros: grupo.map(objeto => {
  //         return {
  //           rut: objeto.rut,
  //           nombreEmpresa: objeto.nombreEmpresa,
  //           clavesObjetos: objeto.clavesObjetos
  //         };
  //       })
  //     };
  //   });
  // }

  // const objetos = [...]; // Tu arreglo de objetos aquí

  // const foliosRepetidosAgrupados = agruparRegistrosPorFechaYFolio(objetos);




