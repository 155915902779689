import apiurl from './apiurl';
import axios from 'axios';

const checkDocumentosCargadosDesdeHasta = async (idToken, params) => {
    // console.log('checkDocumentosCargadosDesdeHasta: ' + JSON.stringify(params));
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                method: 'GET',
                url: apiurl + 'checkDocumentosCargadosDesdeHasta',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken
                },
                params
            })
            resolve(response)
        } catch (error) {
            console.error('Error en la llamada a la API:', error);
            reject(error);
        }
    })
};

export default checkDocumentosCargadosDesdeHasta;
