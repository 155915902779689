import React from 'react'
import { Route, Redirect } from "react-router-dom";

export const PublicRoutes = ({
    isAuthenticated,
    component: Component,
    setStatus,
    setUserSession,
    
...rest}) => {
 
    return (
        <Route {...rest}
        component={(props) =>(
            (!isAuthenticated)
            ?<Component setStatus={setStatus} setUserSession={setUserSession}  {...props}/>
            :<Redirect to="/"/>
        )}
        />
    )
}
