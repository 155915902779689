import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import CheckDocsBDS3DesdeHasta from "../../views/CheckDocsBDS3DesdeHasta";
import CheckDocsComparacion from "../../views/CheckDocsComparacion";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";

const ContainerCheckDocsBDS3DesdeHasta = ({

  showTitle,
  lastcheck,
  tipodocumento,
  startDate,
  endDate,
  handleChangeTipoDocumento,
  HandleChaangeStartDate,
  HandleChaangeEndDate,
  busqueda,
  handleClean

}) => {

  // console.log("showTitle:", showTitle);
  // console.log("lastcheck:", lastcheck);
  // console.log("tipodocumento:", tipodocumento);
  // console.log("startDate:", startDate);
  // console.log("endDate:", endDate);
  // console.log("handleChangeTipoDocumento:", handleChangeTipoDocumento);
  // console.log("HandleChaangeStartDate:", HandleChaangeStartDate);
  // console.log("HandleChaangeEndDate:", HandleChaangeEndDate);
  // console.log("busqueda:", busqueda);
  // console.log("handleClean:", handleClean);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">{showTitle}</Card.Title>
                <p className="card-category">
                  Última Actualización: {lastcheck}
                </p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={12} lg={4}><Form.Label>Tipo&nbsp;Documento</Form.Label><FormControl as="select" value={tipodocumento} multiple={false} onChange={handleChangeTipoDocumento}>
                    {/* <option value="">Todos</option> */}
                    <option value="39">Boleta Electrónica</option>
                    <option value="41">Boleta Exenta Electrónica</option>
                    <option value="0">Todos los Tipos</option>
                  </FormControl></Col>
                  <Col sm={12} md={6} lg={4}><Form.Label>Desde</Form.Label><FormControl type="date" value={startDate} onChange={HandleChaangeStartDate} onClick={HandleChaangeStartDate}/></Col>
                  <Col sm={12} md={6} lg={4}><Form.Label>Hasta</Form.Label><FormControl type="date" value={endDate} onChange={HandleChaangeEndDate} onClick={HandleChaangeEndDate}/></Col>
                </Row>
                <Row>
                  <Col className="mt-3 text-nowrap">
                    <Button variant="primary" onClick={busqueda}>Buscar <span className="nc-icon nc-zoom-split" /></Button>
                  </Col>
                  <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={handleClean}>Limpiar</Button>&nbsp;
                  </Col>
                </Row>
                {/* {dataFiltered != null &&
                  <Row>
                    <Col className="mt-3 text-right text-nowrap">
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Mostrar solo erróneos"
                        checked={checked}
                        onChange={e => setChecked(e.currentTarget.checked)}
                      />
                    </Col>
                  </Row>} */}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContainerCheckDocsBDS3DesdeHasta;