import apiurl from './apiurl';
import axios from 'axios';

const checkDocumentosCargados = async (idToken, fecha) => {
    console.log('checkDocumentosCargados: ' + JSON.stringify(fecha));
    return new Promise(async (resolve, reject) => {
        try {
            const response = await axios({
                'method': 'GET',
                'url': apiurl + 'checkDocumentosCargados',
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + idToken
                },
                'params': {
                    fecha
                }
            })
            resolve(response)
        } catch (error) {
            reject(error);
        }
    })
};

export default checkDocumentosCargados;
