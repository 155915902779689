
import Resumen from "views/Resumen";
import ListaEquipos from "views/ListaEquipos";
import ConsultaEstadoDocs from 'views/ConsultaEstadoDocs';
import ConsultaEstadoFolio from "views/ConsultaEstadoFolio";
import EstadoDocsResumen from "views/EstadoDocsResumen";
import UploadXMLCAF from "views/UploadXMLCAF";
import RangoFolios from "views/RangosFolios";
import CheckDocsBDS3 from "views/CheckDocsBDS3";
import CheckDocumentosCargadosDesdeHasta from "views/CheckDocumentosCargadosDesdeHasta";
// import CheckDocsComparacion from "views/CheckDocsComparacion";

const dashboardRoutes = [
  {
    path: "/resumen",
    name: "Resumen",
    icon: "nc-icon nc-chart-pie-35",
    component: Resumen,
    layout: "/admin",
  },
  {
    path: "/lista",
    name: "Lista Equipos",
    icon: "nc-icon nc-notes",
    component: ListaEquipos,
    layout: "/admin",
  },
  {
    path: "/consultaestadodocs",
    name: "Estado Documento",
    icon: "nc-icon nc-zoom-split",
    component: ConsultaEstadoDocs,
    layout: "/admin",
  },
  {
    path: "/estadodocsresumen",
    name: "Resumen Estado Docs",
    icon: "nc-icon nc-zoom-split",
    component: EstadoDocsResumen,
    layout: "/admin",
  },
  {
    path: "/consultaestadofolio",
    name: "Consulta Folios",
    icon: "nc-icon nc-zoom-split",
    component: ConsultaEstadoFolio,
    layout: "/admin",
  },
  {
    path: "/uploadcaf",
    name: "CAF",
    icon: "nc-icon nc-cloud-upload-94",
    component: UploadXMLCAF,
    layout: "/admin",
  },
  {
    path: "/rangofolios",
    name: "Rango Folios",
    icon: "nc-icon nc-layers-3",
    component: RangoFolios,
    layout: "/admin",
  },
  {
    path: "/checkdocumentoscargados",
    name: "Check Documentos BD S3",
    icon: "nc-icon nc-layers-3",
    component: CheckDocsBDS3,
    layout: "/admin",
  },
  {
    path: "/checkdocumentoscargadosdesdehasta",
    name: "Check Documentos BD S3 Rango Fecha RR",
    icon: "nc-icon nc-layers-3",
    component: CheckDocumentosCargadosDesdeHasta,
    layout: "/admin",
  },
  // {
  //   path: "/checkdocumentoscargadosdesdehasta1",
  //   name: "Check Documentos BD S3 Desde/Hasta",
  //   icon: "nc-icon nc-cloud-upload-94",
  //   component: CheckDocsComparacion,
  //   layout: "/admin",
  // } 
];

export default dashboardRoutes;
