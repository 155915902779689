import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import CheckDocsBDS3 from "../../views/CheckDocsBDS3";
import CheckDocsComparacion from "../../views/CheckDocsComparacion";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";


const ContainerCheckDocsBDS3 = ({

  showTitle,
  startDate,
  lastcheck,
  handleOnChangeFilter,
  busqueda,
  handleClose

}) => {


  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">{showTitle}</Card.Title>
                <p className="card-category">
                  Última Actualización: {lastcheck}
                </p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={6} lg={4}><Form.Label>Fecha Comparacion</Form.Label><FormControl type="date" value={startDate} onChange={handleOnChangeFilter} /></Col>
                </Row>
                <Row>
                  <Col className="mt-3 text-nowrap">
                    <Button variant="primary" onClick={busqueda}>Buscar <span className="nc-icon nc-zoom-split" /></Button>
                  </Col>
                  <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={handleClose}>Limpiar</Button>&nbsp;
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContainerCheckDocsBDS3;


