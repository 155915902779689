import apiurl from './apiurl';
const axios = require('axios');

const reenvioDocs = async (params,idToken) =>{
    console.log(params);
    console.log((idToken));
    return new Promise(async (resolve,reject) => {
        try {
            const result = await axios
            .post(apiurl+"reenvioDocs", {
            data: JSON.stringify(params),
            },{
            'headers': {
                'content-type':'application/json',
                'Authorization':"Bearer " + idToken
            },
            'crossdomain': true})
            // console.log(result);
            resolve(result)
           
        } catch (error) {
            console.log(error)
            reject(error);
        }

    })
    
}
export default reenvioDocs;