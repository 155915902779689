import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
// import CheckDocsBDS3 from "../../views/CheckDocsBDS3";
// import CheckDocsComparacion from "../../views/CheckDocsComparacion";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";

const ContainerResultadosCheckDocsBDS3 = ({
    showContainerResultadosCheckDocsBDS3,
    showTitle,
    filter,
    dataFilteredResultados,
    handleOnChangeFilterResultados,
    actionsMemo,
    fixedHeader,
    columns,
    dataFiltered,
    paginationComponentOptions
}) => {
    // console.log('ContainerResultadosCheckDocsBDS3 - showContainerResultadosCheckDocsBDS3:', showContainerResultadosCheckDocsBDS3);
    // console.log('ContainerResultadosCheckDocsBDS3 - showTitle:', showTitle);
    // console.log('ContainerResultadosCheckDocsBDS3 - dataFilteredResultados:', dataFilteredResultados);
    // console.log('ContainerResultadosCheckDocsBDS3 - handleOnChangeFilterResultados:', handleOnChangeFilterResultados);
    // console.log('ContainerResultadosCheckDocsBDS3 - actionsMemo:', actionsMemo);
    // console.log('ContainerResultadosCheckDocsBDS3 - fixedHeader:', fixedHeader);
    // console.log('ContainerResultadosCheckDocsBDS3 - columns:', columns);
    // console.log('ContainerResultadosCheckDocsBDS3 - dataFiltered:', dataFiltered);
    // console.log('ContainerResultadosCheckDocsBDS3 - paginationComponentOptions:', paginationComponentOptions);

    return (
        <>
            <Container fluid show={showContainerResultadosCheckDocsBDS3}>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">{showTitle}</Card.Title>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleOnChangeFilterResultados} />
                                <Col xs="15" className="d-flex justify-content-end">
                                    <Button onClick={actionsMemo.onExport}>Exportar</Button>
                                </Col>
                                <DataTable
                                    className="-striped -highlight"
                                    fixedHeader={true}
                                    columns={columns}
                                    data={dataFiltered}
                                    pagination
                                    paginationComponentOptions={paginationComponentOptions}
                                    actionsMemo={actionsMemo}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ContainerResultadosCheckDocsBDS3;
