import React, { useContext, useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input } from "reactstrap";
import DataTable from 'react-data-table-component';
import Resumen from "../../views/Resumen.js";
import 'assets/css/dataTable.css';
import { actions } from "react-table";
// import CheckDocumentosCargadosDesdeHasta from "../../views/checkDocumentosCargadosDesdeHasta.js";

const ContainerResumen = ({

    showTitle,
    lastcheck,
    filter,
    handleOnChangeFilter,
    actionsMemo,
    columns,
    dataFiltered,
    PaginationOptions,
    dataEstado,
    errors

}) => {

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md="12">
                        <Card className="strpied-tabled-with-hover">
                            <Card.Header>
                                <Card.Title as="h4">{showTitle}</Card.Title>
                                <p className="card-category">
                                    Última Actualización: {lastcheck}
                                </p>
                                <Col lg="4" sm="8">
                                    <Card className="card-stats">
                                        <Card.Body>
                                            <Row>
                                                <Col xs="5">
                                                    <div className="icon-big text-center icon-warning">
                                                        <i className="nc-icon nc-vector text-danger"></i>
                                                    </div>
                                                </Col>
                                                <Col xs="7">
                                                    <div className="numbers">

                                                        <p className="card-category">Cantidad Dispositivos</p>
                                                        <Card.Title as="h4"> {dataEstado && errors}</Card.Title>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Card.Header>
                            <Card.Body className="table-full-width table-responsive px-0">
                                <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleOnChangeFilter} />
                                <Col xs="15" className="d-flex justify-content-end">
                                    <Button onClick={actionsMemo.onExport}>Exportar</Button>
                                </Col>
                                <DataTable className="-striped -highlight"
                                    fixedHeader={true}
                                    columns={columns}
                                    data={dataFiltered}
                                    pagination={true}
                                    paginationComponentOptions={PaginationOptions}
                                    actionsMemo={actionsMemo}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ContainerResumen;
