import React, { useContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { PublicRoutes } from "./PublicRoutes";
import { AccountContext } from "components/Account/Account";
import { Login } from "components/Login/Login";
import Admin from "layouts/Admin";
import { dataContext } from "components/dataContext/dataContext";
import obj from "api/getAllDevices";
import moment from 'moment';

export const AppRouter = () => {

  const [data, setData] = useState({})
  const {user,getSession,dispatch} = useContext(AccountContext);

  const getAllDevices =  (idToken,date) => {

    obj.getDevices(idToken).then((response)=>{
     setData({'data':response.data,'estado':true, 'lastcheck':date});
    //  console.log(response.data);
   
  }).catch((err) =>{
    setData({'data':{},'estado':false, 'lastcheck':date});
    console.error(err);
  });
}
   useEffect( () => {

       getSession().then((session) => {
        if(session){
          const date = moment().format("MMMM Do YYYY, h:mm:ss a");
          const dataSession=session;
          console.log(session)
          // console.log('aaa');
          // console.log(('idToken'in dataSession))
          // if(!('idToken'in dataSession)) {
          //   const action ={
          //     type: types.logout,
              
          //   }
          //   console.log('No tiene sesion');
          //   dispatch(action);
          // }
          localStorage.setItem('userSession',JSON.stringify(dataSession));
          
          getAllDevices(session.idToken.jwtToken,date);
      }
       });
       
   }, [])
    return (
      <Router >
      <dataContext.Provider value ={{data,setData}} >
                
        <Switch>
            <PublicRoutes  
            exact
            path="/login" 
            component={Login}
            isAuthenticated={user.logged}
             />

           <PrivateRoutes 
            path="/" 
            component={Admin}
            isAuthenticated={user.logged}
            />
          </Switch>
      </dataContext.Provider>
      </Router>
      
            
        
    )
}
