import { types } from "../../types/types";

export const authReducer = (state ={}, action) =>{

    // console.log("estoy en authReducer");
    // console.log(action)

    switch (action.type) {
        case types.login:
            return {
                ...action.payload, 
                logged:true
            }
        case types.logout:
            return {
                ...action,
                logged:false
            }
    
        default:
            return state;
    }

}