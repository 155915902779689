import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormGroup, FormControl, Button, Card } from "react-bootstrap";
import { Input, Label } from 'reactstrap';
import Select from 'react-select';
import empresas from 'api/empresas';
import consultaEstado from 'api/consultaEstado';
import { AccountContext } from 'components/Account/Account';

const ConsultaEstadoDocs = () => {

    const [empresa, setEmpresa] = useState('');
    const [empresaList, setEmpresaList] = useState([]);
    const {user,getSession} = useContext(AccountContext);
    const [folio, setFolio] = useState('');
    const [tipodocumento, setTipodocumento] = useState(39);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    const [Message, setMessage] = useState('');
    const [descripcion, setdescripcion] = useState('');
    const [descripcionE, setdescripcionE] = useState('');
    const [detalle, setdetalle] = useState('');
    const [fecharecepcion, setfecharecepcion] = useState('');
    const [loadingOptions, setloadingOptions] = useState(true);
    const handleClose = () => 
    {setShow(false)
    setFolio('');
    };
    let options = [];
    let optionsdoc = [{value:'39',label:'39'},{value:41,label:'41'}];



    const handleSelectChange = (e) =>{
      
        // console.log(e)
      
      setEmpresa(e.value)
      console.log(options)
      }

    const handleFolioChange = (e) =>{

      // console.log(e);
      // console.log(e.target);
    
      setFolio(e.target.value);
    }

    const handletipodocumentoChange = (e) =>{

      // console.log(e);
      // // console.log(e.target);
      // console.log(e.value);
    
      setTipodocumento(e.value);
    }

    


    const handleSubmit = async (e) =>{
         e.preventDefault()
        // console.log(date);
        // console.log(empresa)
        // console.log(user.idToken.jwtToken)
        if(empresa==''){
          setMessage('Por favor seleccione una empresa');
          setLoading(false);
          setShow(true);
          return;
        }

        if(folio==''){
          setMessage('Por favor escriba un número de folio');
          setLoading(false);
          setShow(true);
          return;
        }
        setLoading(true);
        setShow(true);

        // console.log(folio,empresa,tipodocumento);
        const rut = empresa;
        const params= {rut,folio,tipodocumento}
        
        const idToken= await getSession().then((session) => {return session.idToken.jwtToken});
        // console.log(params,idToken);
        try {
          const resp = await consultaEstado(params,idToken);
          setLoading(false);
          console.log(resp);
          setdetalle(resp.detalle);
          setdescripcion(resp.descripcion);
          setdescripcionE(resp.descripcionE);
          setfecharecepcion(resp.fecha_recepcion);
          console.log(folio);

        } catch (error) {
          setMessage('Error');
          setLoading(false);
        }
       
        
        

        //Consultar a Api por documento (folio,rutEmpresa)
    }

    useEffect(() => {
      
        // let isMounted = true
        getSession().then((session) => {
          
          // let mounted = true;
          // setloadingOptions(true);
          empresas.getEmpresas(session.idToken.jwtToken).then((response) => {
                setEmpresaList(response.data)          
              }).catch((error) => {
                if (axios.isCancel(error)) {
                  return false;
              }
                console.log(error)
              })
            
            return () => { isMounted = false };
        });
      }, []);

    useEffect(() => {
        empresaList.forEach((element)=>{
          if(element.rut!=1){
           options.push({'value': element.rut, 'label': element.rut+" - "+ element.name})
          }
         
        })
        console.log(options);
        // setloadingOptions(false);
       }, [empresaList])


  return (
    <>
      <Container>
          <Form>
          <FormGroup>
          <Label>
            Empresa
          </Label>
          <Col xs="12" sm="12" md="8">
            <Select options={options}
            // isLoading={loadingOptions}        
            onChange={handleSelectChange}
            placeholder='Empresa' />
          </Col>
          
          </FormGroup>

          <FormGroup>
          <Label>
            Folio
          </Label>
          <Col xs="12" sm="12" md="8">
          <Input value ={folio} onChange={e => handleFolioChange(e)}/>
          </Col>          
          </FormGroup>

          <FormGroup>
          <Label>
            Tipo de Documento
          </Label>
          <Col xs="12" sm="12" md="8">
            <Select options={optionsdoc}
            defaultValue={{value:'39',label:'39'}}         
            onChange={e =>handletipodocumentoChange(e)}
            />
          </Col>
          </FormGroup>

          <Button onClick={handleSubmit}>Consultar documento</Button>
          </Form>
          </Container>

          <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Consulta Estado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {loading && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}
        <h5>{!loading && <> Folio: {folio}</>}</h5>
        <p>{!loading && <> Estado: {descripcion}</>}</p>
        <p>{!loading && <> Descripción: {descripcionE}</>}</p>
        <p>{!loading  && detalle != null && detalle!='' &&<> Detalle: {detalle}</>}</p>
        <p>{!loading && <> Fecha Recepción: {fecharecepcion}</>}</p>
        {!loading && Message}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cerrar
          </Button>
          
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConsultaEstadoDocs;
