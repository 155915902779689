import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { Container, Row, Col, Modal, Spinner, Form, InputGroup, FormControl, Button, Card } from "react-bootstrap";
import { dataContext } from "components/dataContext/dataContext";
import DataTable, { ExpanderComponentProps } from 'react-data-table-component';
import '../assets/css/dataTable.css';
import downloadCSV from '../csv/downloadCSV';
import Select from 'react-select';
import { CardHeader, Input, Label } from "reactstrap";
import consultaEstadoDocs from 'api/consultaEstadoDocs';
import { AccountContext } from 'components/Account/Account';
import consultaPorEstado from "api/consultaPorEstadoDocs";
import consultaEstado from 'api/consultaEstado';
import reenvioDocs from "api/reenvioDocs";

function EstadoResumen() {

  let today = new Date();
  let miliseconds = today.getTime() - 24 * 60 * 60 * 1000;//restar 2 días
  let yesterday = new Date(miliseconds);
  const [tipodocumento, setTipodocumento] = useState("39");
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [loading, setLoading] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [filtered, setFiltered] = useState(null)//Filtro para eliminar elementos nulos y asignar solo atributos vistos en tabla
  const [filter, setFilter] = useState('');//value input
  const [dataFiltered, setDataFiltered] = useState(null);
  const [dataDocs, setDataDocs] = useState(null);//datos filtrados del input search
  const [Message, setMessage] = useState('');
  const [modalTitle, setmodalTitle] = useState('');
  
  const { user, getSession } = useContext(AccountContext);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const [detailShow, setDetailShow] = useState(false);
  const [detailData, setDetailData] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState(false);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  //d
  // console.log(dataFiltered);


  const handleClose = () => {
    setShow(false)
  };
  const handleCloseDetail = () => {
    setDetailShow(false)
  };

  const busqueda = async (e) => {
    e.preventDefault()


    setMessage('Cargando');
    setLoading(true);
    setmodalTitle('Consulta Estado Resumen')
    setShow(true);

    console.log('busqueda')

    const params = { startDate, endDate, tipodocumento }

    const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
    // console.log(params,idToken);
    try {
      const resp = await consultaEstadoDocs(params, idToken);
      if (checked) {

        const aux = filtered.filter(
          element => element.documentos_no_autorizados > 0 ||
            element.no_recibidos > 0 ||
            element.nulos > 0 ||
            element.recibidos_con_errores > 0||
            element.documentos_reenviados > 0

        )

        setDataFiltered(aux);
      }
      else {
        console.log('resp')
        console.log(resp);
        setDataFiltered(resp)
        setFiltered(resp)
      }


      setLoading(false);
      setShow(false);


    } catch (error) {
      console.log(error)
      setMessage('Error');
      setLoading(false);
    }




    //Consultar a Api por documento (folio,rutEmpresa)
  }

  const handleButtonClick = async (row, estado) => {

    let RUT = row.rut;

    const params = { estado, RUT, startDate, endDate, tipodocumento }
    console.log('clicked');
    console.log(params);
    setmodalTitle('Consulta Estado Resumen')
    setMessage('Cargando');
    setLoading(true);

    setShow(true);

    try {
      const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
      const resp = await consultaPorEstado(params, idToken);
      console.log('resp', resp)
      setDetailData(resp)
    } catch (error) {
      console.log(error)
      setMessage('Error');
      setLoading(false);
    }
    setLoading(false);
    setShow(false);
    setDetailShow(true)



  };


  const handleChange = (e) => {
    console.log('handleChange')
    e.preventDefault();
    setFilter(e.target.value)
    const aux = filtered.filter(
      element => element.name.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())

    )
    setDataFiltered(aux);

  }


  useEffect(() => {
    console.log('checked');
    console.log(checked);
    console.log('filtered: ', filtered);

    if (checked) {

      const aux = filtered.filter(
        element => element.documentos_no_autorizados > 0 ||
          element.no_recibidos > 0 ||
          element.nulos > 0 ||
          element.recibidos_con_errores > 0 ||
          element.documentos_reenviados
      )
      setDataFiltered(aux);
    }
    else {
      setDataFiltered(filtered);
    }

  }, [checked])

  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  useEffect(() => {
    // console.log(yesterday);

    const firstDayCurrentMonth = getFirstDayOfMonth(
      yesterday.getFullYear(),
      yesterday.getMonth(),
    );

    setStartDate(firstDayCurrentMonth.toISOString().slice(0, 10));
    setEndDate(yesterday.toISOString().slice(0, 10));
    // console.log(firstDayCurrentMonth);

  }, [])



  // useEffect(() => {
  //   // console.log(yesterday);
  //  console.log('dsadsdsa');

  // }, [ExpandedComponent])

  const columns = [
    {
      name: 'Rut',
      selector: row => row.rut,
      sortable: true,
      // width: '7%',
      maxWidth: '50px',
    },
    {
      name: 'Empresa',
      selector: row => row.name,
      sortable: true,
      // width: '50%',
      wrap: true,
      format: row => `${row.name}`,
      // maxWidth: '700px',
    },
    {
      name: 'Aceptados',
      selector: row => row.documentos_aceptados,
      cell: (row) => <div style={{ width: '18rem', cursor: 'default' }} >{row.documentos_aceptados}</div>,
      sortable: true,
      // width: '10%',
      maxWidth: '50px',
      compact: true
    },
    {
      name: 'No Autorizados',
      // selector: row => row.documentos_no_autorizados,
      cell: (row) => <div style={row.documentos_no_autorizados != 0 ? { width: '18rem', cursor: 'pointer' } : null} onClick={row.documentos_no_autorizados != 0 ? () => { handleButtonClick(row, 'No Autorizado') } : null}>{row.documentos_no_autorizados}</div>,
      sortable: true,
      // width: '10%',
      maxWidth: '50px',
      compact: true

    },
    {
      name: 'No recibidos',
      // selector: row => row.no_recibidos,
      cell: (row) => <div style={row.no_recibidos != 0 ? { width: '18rem', cursor: 'pointer' } : null} onClick={row.no_recibidos != 0 ? () => { handleButtonClick(row, 'No recibido') } : null}>{row.no_recibidos}</div>,
      sortable: true,
      // width: '10%',
      maxWidth: '50px',
      compact: true
    },
    {
      name: 'No enviados',
      cell: (row) => <div style={row.nulos != 0 ? { width: '18rem', cursor: 'pointer' } : null} onClick={row.nulos != 0 ? () => { handleButtonClick(row, 'isNull') } : null}>{row.nulos}</div>,
      // selector: row => row.nulos,
      sortable: true,
      // width: '10%',
      maxWidth: '50px',
      compact: true
    },
    {
      name: 'Con errores',
      cell: (row) => <div style={row.recibidos_con_errores != 0 ? { width: '18rem', cursor: 'pointer' } : null} onClick={row.recibidos_con_errores != 0 ? () => { handleButtonClick(row, 'Recibido con errores') } : null}>{row.recibidos_con_errores}</div>,
      // selector: row => row.recibidos_con_errores,
      sortable: true,
      compact: true,
      maxWidth: '50px',
      // width: '10%'

    },
    {
      name: 'Reenviados',
      cell: (row) => <div style={row.documentos_reenviados != 0 ? { width: '18rem', cursor: 'pointer' } : null} onClick={row.documentos_reenviados != 0 ? () => { handleButtonClick(row, 'Reenviado') } : null}>{row.documentos_reenviados}</div>,
      // selector: row => row.recibidos_con_errores,
      sortable: true,
      compact: true,
      maxWidth: '50px',
      // width: '10%'

    },
    // {

    //   cell: (row) => <Button color="secondary" onClick={() => { handleButtonClick(row) }} >Descargar</Button>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   width: '12%'
    // },
  ];



  const ExpandedComponent = ({ data }) => {
    // const [detailDocs, setdetailDocs] = useState(null);
    const [siiData, setSiiData] = useState(null);

    const respPorDocumento = async (data) => {
      const { rut, folio, tipodocumento } = data;
      const params = { rut, folio, tipodocumento }
      const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
      try {
        const resp = await consultaEstado(params, idToken);
        // setLoadingDetail(false);
         console.log(resp);
        var json = {
          "Descripcion": resp.descripcion,
          "Descripcion Error": resp.descripcionE,
          "Detalle": resp.detalle,
          "Fecha Recepción": resp.fecha_recepcion
        }
        console.log(json)
        setSiiData(json)
        // setdetailDocs(resp)

      } catch (error) {

      }

    }

    useEffect(() => {
      // loadDataOnlyOnce();
      respPorDocumento(data);
    }, [data]);


    // setLoadingDetail(false)
    console.log(data);
    let tieneData = (siiData != null);
    console.log(tieneData)
    return (tieneData ? <pre>{JSON.stringify(siiData, null, 2)}</pre> : <pre> <div className="ml-2"><h4>Cargando{loadingDetail && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}</h4> </div> </pre>);
    // return <pre> <div className="ml-2"><h4>Cargando{loadingDetail && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}</h4> </div> </pre>
  }


  // useEffect(() => {
  //   console.log('datadetaaaaail')
  //   console.log(loadingDetail);
  //   // setLoadingDetail(false);
  // }, [detailDocs])
  // useEffect(()=> {
  //   console.log('useefeeeect')
  // },[ExpandedComponent]); 

  const columnsDetallesDoc = [
    {
      name: 'Folio',
      selector: row => row.folio,
      sortable: true,
      width: '8%',
    },
    {
      name: 'Device_id',
      selector: row => row.device_id,
      sortable: true,
      width: '27%'
    },
    {
      name: 'RUT',
      selector: row => row.rut,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: row => row.fechaemision,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Tipo Documento',
      selector: row => row.tipodocumento,
      // cell: (row) => <div style={{ width: '18rem', cursor: 'pointer' }} onClick={() => { handleButtonClick(row) }}>{row.documentos_no_autorizados}</div>,
      sortable: true,
      width: '12%'

    },
    {
      name: 'Valor Total',
      selector: row => row.valortotal,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Valor Neto',
      selector: row => row.valorneto,
      sortable: true,
      width: '10%'
    },
    {
      name: 'Valor I.V.A',
      selector: row => row.valoriva,
      sortable: true,
      width: '10%'
    },
    // {

    //   cell: (row) => <Button color="secondary" onClick={() => { Reenvio(row) }} >Reenviar</Button>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   width: '12%'
    // },
  ];
  const paginationOpciones = {
    rowsPerPageText: 'Filas por página',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsText: 'Todos'
  }

  const Reenvio =async(params)=>{
    console.log(params);
    setDetailShow(false);
    setmodalTitle('');
    setMessage('Reenviando documentos');
    setLoading(true);
    setShow(true);
    const idToken = await getSession().then((session) => { return session.idToken.jwtToken });
    console.log('token', idToken);
    try {
      const resp = await reenvioDocs(params, idToken);
      
      console.log(resp);
     
      setLoading(false);
      setmodalTitle('')
      setMessage('Documentos Reenviados');
     
     
    

    } catch (error) {

    }

  }

  const handleCheckTable = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    // console.log('Selected Rows: ', selectedRows);
    setSelectedRows(selectedRows)
  };

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>Exportar</Button>;
  // const Renviar = ({ }) => <Button >Renviar seleccionados</Button>;
  const actionsMemo = useMemo(() => <Export onExport={() => downloadCSV(dataFiltered)} />, [dataFiltered]);
  const actionsMemoDetail = useMemo(() =>

   <Export onExport={() => downloadCSV(detailData)} />

    , [detailData]);
  const contextActions = React.useMemo(() => {
    const handleSelected = async() => {
    
      var selectedList =[];
      // console.log(selectedRows)
      selectedRows.forEach(element => {
        var object = { 
          folio: element.folio,
          fechaemision: element.fechaemision,
          tipodocumento: element.tipodocumento
        }
        selectedList.push(object)
        // console.log(element.folio)
      })
      const rut = selectedRows[0].rut;
      const params = {rut,selectedList}
      
      await Reenvio(params);
      
    };

    return (
      <Button onClick={handleSelected}>
        Reenviar seleccionados
      </Button>
    );
  }, [detailData, selectedRows, toggleCleared]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Estado Resumen Documentos</Card.Title>

              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm={12} md={12} lg={4}><Form.Label>Tipo&nbsp;Documento</Form.Label><FormControl as="select" value={tipodocumento} multiple={false} onChange={(e) => setTipodocumento(e.target.value)}>
                    <option value="">Todos</option>
                    <option value="39">Boleta Electrónica</option>
                    <option value="41">Boleta Exenta Electrónica</option>
                  </FormControl></Col>
                  <Col sm={12} md={6} lg={4}><Form.Label>Desde</Form.Label><FormControl type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /></Col>
                  <Col sm={12} md={6} lg={4}><Form.Label>Hasta</Form.Label><FormControl type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} /></Col>
                </Row>




                <Row>
                  <Col className="mt-3 text-nowrap">
                    <Button variant="primary" onClick={busqueda}>Buscar <span className="nc-icon nc-zoom-split" /></Button>
                  </Col>


                  <Col className="mt-3 text-right text-nowrap">
                    <Button variant="secondary" onClick={() => { setStartDate(""); setEndDate(""); setDocType(""); setFolio("") }}>Limpiar</Button>&nbsp;
                  </Col>
                </Row>

                {dataFiltered != null &&
                  <Row>
                    <Col className="mt-3 text-right text-nowrap">

                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Mostrar solo erróneos"
                        checked={checked}
                        onChange={e => setChecked(e.currentTarget.checked)}

                      />
                    </Col>

                  </Row>}
              </Card.Body>

            </Card>

            <Card>
              <CardHeader>
                Resultados
              </CardHeader>
              <Card.Body className="table-full-width table-responsive px-0">
                <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleChange} />

                {dataFiltered != null && <DataTable className="-striped -highlight"
                  fixedHeader={true}
                  columns={columns}
                  data={dataFiltered}
                  pagination
                  paginationComponentOptions={paginationOpciones}
                  actions={actionsMemo}

                />}

              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{Message}{loading && <Spinner animation="border" role="status" className='sticky-top'></Spinner>}</h4>


          </Modal.Body>
          <Modal.Footer>
            {!loading &&
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
            }

          </Modal.Footer>
        </Modal>


        <Modal show={detailShow} onHide={handleCloseDetail}
          size="xl"

          scrollable
          animation



        >
          <Modal.Header>
            <Modal.Title>Detalle Documentos</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col >

              <Card>
                <CardHeader>
                  Resultados
                </CardHeader>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Input name="search" value={filter} autoComplete="off" placeholder="Filtrar" className="mb-3" onChange={handleChange} />

                  {detailData != null && <DataTable className="-striped -highlight"
                    fixedHeader={true}
                    columns={columnsDetallesDoc}
                    data={detailData}
                    pagination
                    expandableRows={true}
                    expandOnRowClicked={true}
                    selectableRows
                    onSelectedRowsChange={handleCheckTable}
                    expandableRowsComponent={ExpandedComponent}
                    contextActions={contextActions}
                    // expandableRowsComponentProps={{"someTitleProp": someTitleProp}}  
                    paginationComponentOptions={paginationOpciones}
                    actions={actionsMemoDetail}
                  />}

                </Card.Body>
              </Card>
            </Col>
          </Modal.Body>
          <Modal.Footer>

            <Button variant="secondary" onClick={handleCloseDetail}>
              Cerrar
            </Button>


          </Modal.Footer>
        </Modal>


      </Container>
    </>
  );
}

export default EstadoResumen;
